import React, { useEffect } from "react";
import { SuccessStoriesHero } from "./index";
import { BackgroundSideComponent } from "../Home";
import { leftBgImage } from "../../../Assets";

const SuccessStoriesComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="w-full flex-col flex-center gap-[2rem] ">
      <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[10%]"}
      />
      <SuccessStoriesHero />
    </div>
  );
};

export default SuccessStoriesComponent;
