import React from "react";
import {
  Description,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { esurveillanceData } from "../../../../Constants";

const Esurveillance = () => {
  const {
    id,
    features,
    featuresTitle,
    description,
    image1,
    image1Description,
    subdescription1,
    subtitle1,
    title,
  } = esurveillanceData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem]">
      <Heading title={title} />
      <Description text={description} />
      <div className="flex justify-center items-start gap-[2rem] flex-wrap mt-[1rem] ">
        <div className="w-[14rem] xl:w-[16rem] flex-center flex-col gap-[2rem] ">
          <img
            className="size-full object-contain"
            src={image1}
            alt="Intrusion and Fire Alerting Panel"
          />
          <Description text={image1Description} className={"font-bold"} />
        </div>
        <div className="flex-center lg:flex-start flex-col gap-[2rem] max-w-[24rem] xl:max-w-[28rem]">
          <Heading
            title={subtitle1}
            className={"text-primary text-[1.1rem] leading-tight "}
          />
          <hr className="w-full h-[2px] bg-black " />
          <Description text={subdescription1} className={"text-start"} />
        </div>
        <div className="flex-start md:flex-start gap-[2rem] flex-wrap w-[100%] md:w-[90%] xl:w-[80%] 2xl:w-[68%] ">
          <FeaturePoints
            title={featuresTitle}
            points={features}
            showEnquireButton
          />
        </div>
      </div>
    </div>
  );
};

export default Esurveillance;
