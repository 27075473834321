import React, { useState } from "react";
import { useGlobalContext } from "../../context/useGlobalContext";
import axios from "axios";
import { serviceHeroPageData } from "../../Constants";
import Loader from "./Loader";
import Button from "./Button";
import toast from "react-hot-toast";
import Heading from "./Heading";

const EnquireNowPopup = () => {
  const { isEnquireNowFormOpen, setIsEnquireNowFormOpen } = useGlobalContext();

  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    organization: "",
    contact_no: "",
    services: [],
    comment: "",
  });

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.user_name) {
      newErrors.user_name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!validateEmail(formData?.email)) {
      newErrors.email = "Enter Valid Email";
      valid = false;
    }

    if (!formData.organization) {
      newErrors.organization = "Organization name is required";
      valid = false;
    }

    if (!formData.contact_no) {
      newErrors.contact_no = "Contact number is required";
      valid = false;
    }

    if (formData.services.length === 0) {
      newErrors.services = "At least one service is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        services: [...formData.services, value],
      });
    } else {
      setFormData({
        ...formData,
        services: formData.services.filter((service) => service !== value),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await axios
        .post("/iesg-website/book-demo", {
          ...formData,
          enquiry_subject: `New Client Inquiry from ${formData.organization}`,
          enquiry_body: `Dear Team,\n\nA new client has submitted an inquiry through our website. Here are the details:\n\nName : ${
            formData.user_name
          }\nEmail : ${formData.email}\nPhone : ${
            formData.contact_no
          }\nCompany : ${formData.organization}\nMessage : ${
            formData.comment
          }\nServices : ${formData.services.join(" , ")}
          \n\nPlease follow up with this client at your earliest convenience.\n\nBest regards,\nSales Manager\nIESG Pvt. Ltd`,
          feedback_subject: `Thank you for the inquiry!`,
          feedback_body: `Hi, Thank you for reaching out to us. We appreciate your interest in our products and services. Our team will reach out to you shortly to discuss your requirements in detail.\n\nIf you have any immediate questions or need further information, please feel free to contact us.\n\nWe look forward to successful collaboration!`,
        })
        .then((response) => {
          console.log(response?.data?.data);
          toast.success("Form submitted successfully");
          setFormData({
            user_name: "",
            email: "",
            organization: "",
            contact_no: "",
            services: [],
            comment: "",
          });
          setIsEnquireNowFormOpen(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      setLoading(false);
    }
  };

  if (!isEnquireNowFormOpen) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-[9999]`}
    >
      <div
        className={`flex-center flex-col bg-white rounded-[1rem]  `}
        style={{ zIndex: "2000" }}
        id="book-a-demo"
      >
        <form
          id="book-demo-form"
          style={{
            background:
              "linear-gradient(270deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%)",
            boxShadow: "0px 4px 11.4px 1px rgba(151, 151, 151, 0.25)",
          }}
          onSubmit={handleSubmit}
          className={`bg-[#fff] p-8 w-[320px] sm:w-[400px] md:w-[450px] lg:w-[550px] xl:w-[550px] 2xl:w-[650px] rounded-[1rem] flex flex-col flex-nowrap justify-center items-start`}
        >
          <div className="w-full flex justify-between items-center mb-[1rem]">
            <Heading title={"BOOK A DEMO"} />
          </div>
          <p className="font-normal text-xs mb-4">
            By submitting this form, I consent to the processing of the personal
            data that I provide to KIAM Intl. Pvt. Ltd. in accordance with and
            as described in the Privacy Policy. Refund Policy, Terms and
            Condition
          </p>
          <div
            className={`flex justify-start items-center flex-wrap gap-2 lg:gap-4`}
          >
            <div className={`flex-start flex-col w-[45%] gap-[0.6rem] `}>
              <input
                // style={{ background: "#ffe4e3" }}
                className="rounded-lg p-2 text-[0.8rem] w-[100%]  border border-black focus:bg-[#ffe4e3] focus:border-2 focus:border-primary "
                placeholder="Enter Your Name"
                name="user_name"
                value={formData.user_name}
                onChange={handleInputChange}
              />
              {errors.user_name && (
                <span className="text-[0.7rem] text-red-500 px-[0.5rem]">
                  {errors.user_name}
                </span>
              )}
            </div>

            <div className={`flex-start flex-col w-[45%] gap-[0.6rem] `}>
              <input
                // style={{ background: "#ffe4e3" }}
                className="rounded-lg p-2 text-[0.8rem] w-[100%]  border border-black focus:bg-[#ffe4e3] focus:border-2 focus:border-primary "
                placeholder="Drop Your Email ID Here"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                  {errors.email}
                </span>
              )}
            </div>

            <div className={` flex-start flex-col w-[45%] gap-[0.6rem] `}>
              <input
                // style={{ background: "#ffe4e3" }}
                className="rounded-lg p-2 text-[0.8rem] w-[100%] border border-black focus:bg-[#ffe4e3] focus:border-2 focus:border-primary"
                placeholder="Organization Name"
                name="organization"
                value={formData.organization}
                onChange={handleInputChange}
              />
              {errors.organization && (
                <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                  {errors.organization}
                </span>
              )}
            </div>

            <div className={`flex-start flex-col w-[45%] gap-[0.6rem] `}>
              <input
                // style={{ background: "#ffe4e3" }}
                className="rounded-lg p-2 text-[0.8rem] w-[100%] border border-black focus:bg-[#ffe4e3] focus:border-2 focus:border-primary"
                placeholder="Contact No"
                name="contact_no"
                value={formData.contact_no}
                onChange={handleInputChange}
              />
              {errors.contact_no && (
                <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                  {errors.contact_no}
                </span>
              )}
            </div>

            <div className={` flex-start flex-col gap-[0.8rem]`}>
              <p className="font-[500] text-[0.9rem] ">Select Services</p>
              <div
                className={`flex justify-start  flex-wrap items-center gap-[1rem] w-[100%] mb-2`}
              >
                {serviceHeroPageData?.map((service) => (
                  <div
                    key={service.id}
                    className="flex items-center gap-[1rem]"
                  >
                    <input
                      type="checkbox"
                      id={`service-${service.id}`}
                      value={service.id}
                      onChange={handleCheckboxChange}
                      checked={formData.services.includes(String(service.id))}
                      className="cursor-pointer"
                    />
                    <label
                      htmlFor={`service-${service.id}`}
                      className="ml-1 text-[0.7rem] cursor-pointer"
                    >
                      {service.title}
                    </label>
                  </div>
                ))}
              </div>
              {errors.services && (
                <span className="text-[0.8rem] text-red-500">
                  {errors.services}
                </span>
              )}
            </div>

            <textarea
              // style={{ background: "#ffe4e3" }}
              className="rounded-lg p-2 w-[95%] text-[0.8rem] border border-black focus:bg-[#ffe4e3] focus:border-2 focus:border-primary"
              placeholder="Drop your Comment"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
            />

            <div className="w-[100%] flex justify-between gap-[1rem]">
              <div className="flex justify-center items-center w-[70%] lg:w-[70%]">
                <Button
                  onClick={handleSubmit}
                  className="bg-primary flex justify-center gap-4 items-center w-full rounded-md flex-1  p-2 text-[#fff] text-center font-montserrat text-base font-medium  "
                >
                  <p>Send Message</p>
                  {loading ? <Loader /> : null}
                </Button>
              </div>
              <button
                className="w-[6rem] px-2 py-2 bg-gray-300 rounded-md my-1 mr-[1.4rem]"
                onClick={() => setIsEnquireNowFormOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnquireNowPopup;
