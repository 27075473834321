import React from "react";
import { Heading } from "../../ReusableComponents";
import { productsIntroArray } from "../../../Constants/HomePageConstants";
import { Link } from "react-router-dom";

const HomeProducts = () => {
  return (
    <div className="w-full flex-center flex-col gap-[2rem]">
      <Heading
        title={"Products"}
        className={"uppercase text-[1.7rem] font-semibold "}
      />
      <div className="flex-center flex-wrap gap-[2rem] lg:gap-[3.8rem]  ">
        {productsIntroArray?.map((item, index) => (
          <Link to={`/products/product-details?product=${item?.id}`}>
            <div className="flex-center flex-col gap-[1.4rem] hover:scale-105 duration-500 ">
              <div className="flex-center w-[8.25rem]">
                <img
                  src={item?.image}
                  className="size-full object-contain  shadow-2xl shadow-gray-300"
                  alt=""
                />
              </div>
              <div className="flex-center flex-col gap-[0.6rem]">
                <span className="font-semibold text-[0.75rem] uppercase">
                  {item?.title}
                </span>
                <span className="cursor-pointer text-[0.65rem] font-semibold text-primary ">
                  <Link to={`/products/product-details?product=${item?.id}`}>
                    {`Learn More`}&nbsp;&nbsp;&nbsp;{`->`}
                  </Link>
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomeProducts;
