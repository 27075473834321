import React from "react";
import { Button } from "./index";
import { useGlobalContext } from "../../context/useGlobalContext";

const EnquireNowButton = ({ className }) => {
  const { setIsEnquireNowFormOpen } = useGlobalContext();
  return (
    <Button
      className={` py-1 px-[2rem] rounded-lg text-[0.9rem] right-[30rem] blinking-button ${className} `}
      onClick={() => setIsEnquireNowFormOpen(true)}
    >
      Enquire Now
    </Button>
  );
};

export default EnquireNowButton;
