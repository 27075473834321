import React from "react";

const Button = ({ children, className, ...rest }) => {
  return (
    <button className={` text-white bg-primary ${className} `} {...rest}>
      {children}
    </button>
  );
};

export default Button;
