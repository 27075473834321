import React, { useState } from "react";
import {
  Description,
  Heading,
  TransparentButton,
} from "../../ReusableComponents";
import ViewPdf from "./ViewPdf";

const SuccessCard = ({ story }) => {
  const [showPdf, setShowPdf] = useState(false);
  return (
    <div className="flex-center flex-col w-full gap-[2rem]">
      <div className="flex justify-center items-start w-full flex-wrap">
        <div className="w-[20rem] flex-center ">
          <img src={story?.image} alt="" className="object-contain size-full" />
        </div>
        <div className="flex-start flex-col gap-[0.5rem] w-[20rem] shadow-2xl p-[1rem] shadow-gray-200  ">
          <div
            className={
              "text-[0.6rem] px-2 py-1 rounded-2xl font-semibold bg-[#FFF4D8] "
            }
          >
            {story?.category}
          </div>
          <Heading title={story?.title} className={"text-[20px] text-start "} />
          <Description
            text={story?.description}
            className={"text-start text-tertiary text-[0.6rem]"}
          />
          <div className="flex-between w-full my-[0.5rem]">
            <span
              className="text-[0.75rem] sm:text-[0.65rem] text-primary cursor-pointer "
              onClick={() => setShowPdf(!showPdf)}
            >
              {showPdf ? "Show Less ^" : "Show More ->"}
            </span>
            <span className="text-[0.75rem] sm:text-[0.65rem] text-gray-500 ">
              {story?.date} / {story?.timeToRead}
            </span>
          </div>
        </div>
      </div>
      {showPdf && <ViewPdf pathOfPdf={story?.pathOfPdf} />}
    </div>
  );
};

export default SuccessCard;
