import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { leftBgImage, rightBgImage } from "../../../Assets";
import { productHeroPageData } from "../../../Constants";
import { BackgroundSideComponent } from "../Home";

const ProductDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState(searchParams.get("product") || "");

  const handleClick = (id) => {
    setActive(id);
    setSearchParams({ product: id }, { replace: true });
  };

  useEffect(() => {
    if (searchParams.has("product")) {
      setActive(searchParams.get("product"));
      // let elem = document.getElementById(searchParams.get("product"));
      // if (elem) {
      //   elem.scrollIntoView({ behavior: "smooth", block: "start" });
      //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      // }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [searchParams, active]);

  return (
    <div className=" w-full flex-center flex-col gap-[2rem] mt-[2rem] ">
      <div className="flex items-start justify-center flex-wrap gap-[0.8rem] w-full ">
        {productHeroPageData?.map((product, index) => (
          <div
            key={index}
            onClick={() => handleClick(product?.id)}
            className={` cursor-pointer flex-center rounded-lg p-2 px-2 w-[8.5rem] h-[4rem] sm:h-[3.4rem] hover:scale-105 duration-300 hover:text-white ease-in-out hover:bg-primary ${
              active === product?.id ? "bg-primary text-white  " : ""
            } `}
            style={{ boxShadow: "0.5px 0.5px 20px 0.5px #e3e1e1" }}
          >
            <span
              className={`font-semibold text-[0.8rem] sm:text-[0.7rem] text-center ${
                active === product?.id ? "text-white" : ""
              } hover:text-white`}
            >
              {product.title}
            </span>
          </div>
        ))}
      </div>
      <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[10%]"}
      />
      {productHeroPageData?.find((item) => item?.id === active)?.component}
      <BackgroundSideComponent
        image={rightBgImage}
        className={"right-0 top-[10%]"}
      />
    </div>
  );
};

export default ProductDetails;
