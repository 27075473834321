import React from "react";
import {
  Description,
  EnquireNowButton,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { occupancyMonitoringData } from "../../../../Constants";
import { occupancyMonitoringImage } from "../../../../Assets";

const OccupancyMonitoring = () => {
  const { id, description, features, featuresTitle, title } =
    occupancyMonitoringData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem] mt-[1rem] ">
      <div className="flex justify-center items-center gap-[2rem] flex-wrap ">
        <div className="flex-start flex-col gap-[1rem] max-w-[24rem] ">
          <Heading title={title} className={"text-[2rem]"} />
          <Description
            text={description}
            className={"max-w-[24rem] text-start"}
          />
        </div>
        <div className="flex-center flex-col w-[16rem] lg:w-[24rem] ">
          <img
            src={occupancyMonitoringImage}
            alt="Occupancy Monitoring"
            className="size-full object-contain"
          />
        </div>
      </div>
      <div className="flex-start md:flex-start gap-[2rem] flex-wrap w-[100%] md:w-[80%] lg:w-[65%] xl:w-[65%] 2xl:w-[75%] ">
        <FeaturePoints
          title={featuresTitle}
          points={features}
          showEnquireButton
        />
      </div>
    </div>
  );
};

export default OccupancyMonitoring;
