import React from "react";
import { lockHomeImage } from "../../../Assets";
import { homeHeroDescriptionArray } from "../../../Constants";

const HomeSlide1 = () => {
  return (
    <div className="flex-col flex-center mb-[1rem] gap-[0.6rem] ">
      <img src={lockHomeImage} alt="" className="object-contain w-[9.4rem]" />
      <span className="uppercase text-center w-full text-[2.05rem] font-semibold mt-[0.6rem] px-[1rem] tracking-normal text-white ">
        security, safety and efficiency
      </span>
      <div className=" w-[90%] sm:w-[60%] flex justify-center items-center flex-wrap mt-[0.6rem] ">
        {homeHeroDescriptionArray?.map((item, index) => (
          <div className="flex justify-center items-center gap-2">
            <span className="text-white w-[100%] text-[0.75rem]">{item}</span>
            {index !== homeHeroDescriptionArray?.length - 1 && (
              <span className="text-[#FF0000]  text-[0.75rem] mr-[0.3rem]">
                |
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSlide1;
