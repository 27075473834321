import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className={`relative w-full flex-center flex-col `}>
      <Header />
      <div className="app">
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          gutter={800}
          containerClassName=""
          containerStyle={{
            zIndex: 99999,
            margin: "30px 0px",
          }}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
