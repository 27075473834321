import React from "react";
import Heading from "./Heading";
import EnquireNowButton from "./EnquireNowButton";

const FeaturePoints = ({
  title,
  points,
  className,
  showEnquireButton = false,
  image,
  disableLine = false,
  ...rest
}) => {
  return (
    <div className={` flex-start flex-col ${className} `} {...rest}>
      {showEnquireButton && (
        <EnquireNowButton
          className={"text-[0.68rem] mt-[2rem] px-[4.5rem]  "}
        />
      )}
      <div
        className={`flex-start flex-col gap-[0.5rem] ${
          !disableLine ? "border-l" : ""
        } border-black pl-[2rem] pt-[2rem] ml-[0.6rem] lg:ml-[2rem] `}
      >
        <div className="flex-center w-[100%] ">
          <img src={image} alt="" className="object-contain size-full" />
        </div>
        <Heading
          title={title}
          className={"text-primary mb-[1rem] text-center"}
        />
        {points?.map((item, index) => {
          return (
            <li key={index} className="text-[0.85rem] sm:text-[0.78rem]">
              {item}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturePoints;
