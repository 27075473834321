import React from "react";
import Heading from "../../ReusableComponents/Heading";
import {
  technologyBlockDescription,
  technologyBlockTitle,
} from "../../../Constants/HomePageConstants";
import { Button } from "../../ReusableComponents";

const TechnologyBlock = () => {
  return (
    <div className="w-full flex-center flex-col gap-[2rem] mt-[2rem] ">
      <div className="w-[100%] xl:w-[65%] border border-gray-200 bg-[#F5F5F5] h-auto rounded-sm flex-center flex-col p-[1.2rem] gap-[1rem] ">
        <div className="flex-center flex-col max-w-[100%] xl:max-w-[90%] gap-[0.5rem] ">
          <Heading
            title={technologyBlockTitle}
            className={
              "text-[1.7rem] text-secondary text-center leading-tight font-semibold "
            }
          />
          <span className=" text sm:text-[0.68rem] text-secondary opacity-50 text-center max-w-[70%] ">
            {technologyBlockDescription}
          </span>
          <Button
            className={
              "text-[0.68rem] font-medium px-4 py-1  rounded-2xl mt-[0.5rem]"
            }
          >
            REQUEST A QUOTE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TechnologyBlock;
