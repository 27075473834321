import React from "react";

const Description = ({ text, className, ...rest }) => {
  return (
    <div
      className={`text-[0.85rem] sm:text-[0.78rem] text-tertiary max-w-[18rem] md:max-w-[22rem] lg:max-w-[24rem] xl:max-w-[36rem] 2xl:max-w-[44rem] leading-normal text-center  ${className}`}
      {...rest}
    >
      {text}
    </div>
  );
};

export default Description;
