import {
  cctvSolutionsAndAuditsImage,
  eSurveillanceImage,
  esgSolutionsDiagramImage,
  intrusionAndFireAlertingImage,
  intrusionAndFireAlertingSensorsImage,
  twentyfoursevenControlCommand,
} from "../Assets";
import {
  TwentyFourBySevenCommandControl,
  CctvSolutionsAndAudits,
  EsgSolutions,
  Esurveillance,
  IntrusionAndFireAlerting,
} from "../Components/SubComponents/Service/index";

export const serviceHeroPageData = [
  {
    id: "24x7-command-and-control",
    title: "24 x 7 Command & Control",
    component: <TwentyFourBySevenCommandControl />,
  },
  {
    id: "intrusion-and-fire-alerting",
    title: "Intrusion and Fire Alerting",
    component: <IntrusionAndFireAlerting />,
  },
  {
    id: "e-surveillance",
    title: "E-Surveillance",
    component: <Esurveillance />,
  },
  {
    id: "cctv-based-ai-solution-and-audits",
    title: "CCTV based AI solution & audits",
    component: <CctvSolutionsAndAudits />,
  },
  // {
  //   id: "esg-solutions",
  //   title: "ESG Solutions",
  //   component: <EsgSolutions />,
  // },
];

export const intrusionAndFireAlertingData = {
  id: "intrusion-and-fire-alerting",
  title: "Intrusion and Fire Alerting",
  description: "Fire Alarm, Intrusion Alarm Alert Monitoring",
  subtitle1:
    "Detecting a fire early can mean the difference between losing everything and keeping loss and damage to a minimum.",
  subdescription1:
    "Our fire monitoring system entails remote monitoring of fire, smoke alarm, and system health by connecting fire panels to IoT network connective devices and pushing data to cloud hosted software, with a 24*7 centralised monitoring station providing quick responses.",
  featuresTitle: "Sensors",
  features: [
    "Removal Sensor - ATM, UPS, Cheque Drop Box, Air Conditioner",
    "Vibration Sensor To avoid ATM machine tampering",
    "Door Open Sensor Door Open/close monitoring",
    "Panic / Duress Button",
    "Smoke Detector For fire alarm",
    "Alarm panel tamper & Alarm panel Enclosure Tamper",
    "Glass Break Detector",
    "Motion/ Occupancy Sensor",
    "Power Input Mains Input (ON / OFF), Panel Battery Low",
    "Power Output - UPS Output (ON / OFF)",
    "Monitoring of environment/ Analog inputs like Temperature and Humidity",
    "Monitoring of AC power",
  ],
  image1: intrusionAndFireAlertingImage,
  image1Description: "Intrusion and Fire Alerting",
  image2: intrusionAndFireAlertingSensorsImage,
};

export const esurveillanceData = {
  id: "e-surveillance",
  title: "E-Surveillance",
  description:
    "Video Surveillance helps identify security incidents and take actions",
  subtitle1:
    "CCTV is a critical security component for all facilities. Video Surveillance helps identify security incidents and take actions.",
  subdescription1:
    "The majority of locations have CCTV installed, however it is not being watched effectively. When needed, footage is most likely unavailable because the DVR/NVR is not powered or the HDD has failed. Other possibilities include repositioning the camera, camouflaging the camera, or the camera not working.This can be taken care with KiAM 24 x 7 CCTV monitoring services.",
  featuresTitle: "Features of CCTV Surveillance",
  features: [
    "Event based Video surveillance",
    "Night time CCTV monitoring",
    "Evidence support with Event based image and video storage on Cloud",
    "Pattern Analysis for Proactive actions",
    "DVR/ NVR status and recording checks",
    "CCTV Alarm and break down monitoring:",
    "CCTV Camera status",
    "Camera Mask",
    "Camera position shift",
    "CCTV system AMC management",
    "Weekly /Monthly MIS reports for analysis and actions",
  ],
  image1: eSurveillanceImage,
  image1Description: "E-Surveillance",
};

export const cctvSolutionsAndAuditsData = {
  id: "cctv-based-ai-solution-and-audits",
  title: "CCTV based AI solution & audits",
  description: "comprehensive assessment of the security measures    ",
  subtitle1:
    "AI CCTV security audit is a comprehensive assessment of the security measures in place for a CCTV system that utilizes artificial intelligence (AI) for video surveillance.",
  subdescription1:
    "CCTV is a critical security component for all facilities. Video Surveillance helps identify security incidents and take actions.The majority of locations have CCTV installed, however it is not being watched effectively. When needed, footage is most likely unavailable because the DVR/NVR is not powered or the HDD has failed. Other possibilities include repositioning the camera, camouflaging the camera, or the camera not working.This can be taken care with KiAM 24 x 7 CCTV monitoring services.",
  featuresTitle: "Solution",
  features: [
    "One day video compressed in 1 min can help management understand operational data easily for retail store, warehouse, manufacturing unit and offices.",
    "Audit can be conducted using time-lapse video.",
    "Compliance and deviation is easily visible.",
    "Marketing content, bill boards, audit is easy as over the month data can be viewed in just 5 mins.",
    "Helps in understanding site and infra changes.",
    "Effective use of CCTV camera with Camera health monitoring.",
  ],
  image1: cctvSolutionsAndAuditsImage,
  image1Description: "CCTV based AI solution & audits",
};

export const twentyFourBySevenCommandControlData = {
  id: "24x7-command-and-control",
  title: "24x7 Command & Control",
  description: "Which includes CMS, Banking Monitoring & many more",
  subtitle1:
    "This service not only enhances your security but also helps streamline operations, reduce risks, and increase overall efficiency.",
  subdescription1:
    "iAM make multi location offices/ premises safe, secure and efficient by using unique IoT based integrated solution with 24 x 7 monitoring platform. KiAM provides System Integration services, AMC, 24 x 7 Centralized Monitoring of alert and notifications from different systems like Fire Alarm, Access Control, Biometrics, Intrusion alarm, Video Surveillance, Entrance Automation, Guard Monitoring, Environmental Parameter Monitoring like Temperature and humidity along with Energy monitoring.",
  features: {
    "Customized Solutions":
      "We tailor our services to meet your specific needs, whether it's for industrial, commercial, or residential applications.",
    Scalability:
      "As your business grows, our system can scale to accommodate your expanding requirements seamlessly.",
    "Cost-Effective":
      "By offering this as a service, you can enjoy the benefits of a sophisticated monitoring system without the upfront infrastructure costs.",
    "Expert Support":
      "Our team of experts is available around the clock to provide support and ensure the system functions optimally.",
  },
  image1: twentyfoursevenControlCommand,
  image1Description: "24x7 Command & Control",
};

export const esgSolutionsData = {
  id: "esg-solutions",
  title: "ESG Solutions",
  description: "Create reports with an easy to use drag-and-drop designer.",
  subtitle1: "Energy Management and saving IoT Solution",
  subdescription1: [
    "Future proof IoT solution.",
    "Wi-Fi based solutions",
    "Control AC as per standard (No warranty issues)",
    "Use same IR remote interface to control AC.",
    "Cloud hosted solution.",
    "Centrally managed.",
  ],
  subdescription2: [
    "ROI less than 1 year",
    "15 to 20 % energy saving.",
    "Predictive maintenance management.",
    "Comfort to customer.",
    "Green building compliant.",
    "Save power save environment.",
    "Control over store assets centrally",
    "Improved bottom line.",
  ],
  image1: cctvSolutionsAndAuditsImage,
  image2: esgSolutionsDiagramImage,
  image1Description: "CCTV based AI solution & audits",
};
