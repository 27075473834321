import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ibexBrochurePdf, kiamLogo, kiamWhiteLogo } from "../../Assets";
import { navLinks } from "../../Constants";
import { Button } from "../ReusableComponents";
import { IoMdClose } from "react-icons/io";
import { HiOutlineMenu } from "react-icons/hi";

const Header = ({ isHomePage = false }) => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);

  const handleDownload = () => {
    const filePath = ibexBrochurePdf;
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "kiam.pdf";
    document.body.appendChild(link);
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div
      className={`flex flex-col flex-center sticky top-0 z-[10] w-full px-[1rem] lg:px-[2rem] ${
        isHomePage ? "bg-none" : "bg-white"
      } `}
    >
      <div className="flex-between w-full mt-[0.5rem] mb-[0.4rem]">
        <Link
          to="/"
          className={`flex-center flex-wrap`}
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img
            src={isHomePage ? kiamWhiteLogo : kiamLogo}
            alt="KIAM"
            className="w-[7rem]"
          />
        </Link>
        <ul className="list-none hidden md:flex items-center gap-[1.6rem] ">
          {navLinks.map((nav) => (
            <Link
              key={nav.id}
              to={`${nav.id}`}
              className={`${
                active === nav.title
                  ? "text-primary"
                  : isHomePage
                  ? "text-white"
                  : "text-black"
              } hover:text-primary h-auto hover:scale-105 text-[0.67rem] duration-300 font-medium cursor-pointer relative z-[99999]`}
              onClick={() => {
                setActive(nav.title);
              }}
            >
              <div className="hover:scale-105">{nav.title}</div>
            </Link>
          ))}
          <Button
            className="rounded-[1rem] px-3 py-[4px] text-center font-medium text-[0.67rem]"
            onClick={handleDownload}
          >
            Download Brochure
          </Button>
        </ul>

        <div className="md:hidden flex flex-1 justify-end items-center">
          <div className="z-[100]">
            {toggle ? (
              <IoMdClose size={24} onClick={() => setToggle(false)} />
            ) : (
              <HiOutlineMenu
                color={isHomePage ? "white" : "black"}
                size={24}
                onClick={() => setToggle(true)}
              />
            )}
          </div>

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-white absolute top-14 right-[2rem] mx-4 my-2 min-w-[10rem] z-[9999] rounded-xl`}
            style={{ border: "1px solid #000" }}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-[1rem]  ">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[1.1rem] relative  ${
                    active === nav.title ? "text-primary" : "text-black"
                  }`}
                  onClick={() => {
                    setActive(nav.title);
                  }}
                >
                  <Link onClick={() => setToggle(false)} to={`${nav.id}`}>
                    {nav.title}
                  </Link>
                </li>
              ))}
              <li
                key={"download-brochure"}
                className={`font-medium cursor-pointer text-[1.1rem] ${
                  active === "download-brochure" ? "text-primary" : "text-black"
                }`}
                onClick={() => {
                  setToggle(!toggle);
                  setActive("download-brochure");
                }}
              >
                <button onClick={handleDownload}>Download Brochure</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!isHomePage ? <hr className="w-full mt-[0.2rem]" /> : null}
    </div>
  );
};

export default Header;
