import React from "react";
import { intrusionAndFireAlertingData } from "../../../../Constants";
import {
  Description,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";

const IntrusionAndFireAlerting = () => {
  const {
    id,
    title,
    subdescription1,
    features,
    featuresTitle,
    description,
    image1,
    image1Description,
    image2,
    subtitle1,
  } = intrusionAndFireAlertingData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem]">
      <Heading title={title} />
      <Description text={description} />
      <div className="flex justify-center items-start gap-[2rem] flex-wrap mt-[1rem] ">
        <div className="w-[16rem] flex-center flex-col gap-[2rem] ">
          <img
            className="size-full object-contain"
            src={image1}
            alt="Intrusion and Fire Alerting Panel"
          />
          <Description text={image1Description} className={"font-bold"} />
        </div>
        <div className="flex-center lg:flex-start flex-col gap-[2rem] max-w-[24rem] xl:max-w-[28rem]">
          <Heading
            title={subtitle1}
            className={"text-primary text-[1.1rem] leading-tight "}
          />
          <hr className="w-full h-[2px] bg-black " />
          <Description text={subdescription1} className={"text-start"} />
        </div>
        <div className="flex-center gap-[2rem] flex-wrap w-[100%] ">
          <div className="flex-start md:flex-start gap-[2rem] flex-wrap w-[100%] md:w-[40%] xl:w-[40%] 2xl:w-[35%] ">
            <FeaturePoints
              title={featuresTitle}
              points={features}
              showEnquireButton
            />
          </div>
          <div className="w-[18rem] xl:w-[20rem] flex-center ">
            <img
              className="size-full object-contain"
              src={image2}
              alt="Intrusion and Fire Alerting Panel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntrusionAndFireAlerting;
