import React from "react";
import { Heading } from "../../ReusableComponents";
import { articlesForYouArray } from "../../../Constants";
import { Link } from "react-router-dom";

const ArticlesForYou = () => {
  return (
    <div className="flex-center w-full flex-col gap-[1rem] my-[2rem] mt-[3rem]">
      <div className="w-full flex-center flex-col flex-wrap gap-[2rem]">
        <Heading
          title={"Articles for you"}
          className={
            "text-secondary uppercase font-semibold text-[1.6rem] text-center "
          }
        />
        <div className="flex-center flex-row flex-wrap gap-[2rem]">
          {articlesForYouArray?.map((article, index) => (
            <Link
              key={index}
              to={`/articles?article=${article?.id}`}
              className="flex-center flex-col gap-[1rem] hover:scale-105 duration-300 "
            >
              <div className="w-[12.5rem] h-[16rem]">
                <img
                  src={article?.image}
                  alt=""
                  className="object-fill size-full"
                />
              </div>
              <span className="cursor-pointer text-[0.7rem] font-semibold mt-[0.4rem] text-primary ">
                {`Learn More`}&nbsp;&nbsp;&nbsp;{`->`}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesForYou;
