import React from "react";
import { aboutPageData, aboutPageDescription } from "../../../Constants";
import { Description, TransparentButton } from "../../ReusableComponents";
import Heading from "../../ReusableComponents/Heading";

const AboutHero = () => {
  return (
    <div className="w-full flex-center flex-col gap-[1rem]">
      {/* <TransparentButton title={"ABOUT US"} /> */}
      <Heading
        title={"About KIAM INTL PVT. LTD."}
        className={"text-secondary text-[1.6rem] mt-[1.4rem] "}
      />
      <div className=" flex-center w-[100%] xl:w-[40%]">
        <Description
          text={aboutPageDescription}
          className={"text-[0.68rem] opacity-50"}
        />
      </div>
      <div className="w-full flex-center flex-wrap gap-[1rem] sm:gap-[2rem] lg:gap-[2.5rem] mt-[2rem]">
        {aboutPageData?.map((item, index) => (
          <div
            key={index}
            className="flex-center odd:flex-col even:flex-col-reverse gap-[1rem]"
          >
            <div className="relative w-[9.5rem] h-auto shadow-2xl rounded-lg shadow-gray-300 ">
              <img
                src={item?.image}
                alt={item?.title}
                className="size-full rounded-lg transform "
              />
              <div className="absolute w-full flex-center bg-black/40 top-1/2 -translate-y-1/2">
                <span className="text-white text-[2.3rem] font-bold flex-center gap-1 ">
                  <span>{item?.count}</span>
                  <span className="pb-2">+</span>
                </span>
              </div>
            </div>
            <span className="text-[0.9rem] font-semibold ">{item?.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutHero;
