import {
  addressableFireSystemsLogo,
  cctvLogo,
  centralizedDisplayAndSignageLogo,
  energyManagementSolutionsLogo,
  instrusionLogo,
  intrusionImage,
  iotBasedIntrusionImage,
  occupancyMonitoringLogo,
  smartIotLogo,
} from "../Assets";
import {
  AddressableFireSystem,
  BuildingManagementSystem,
  Cctv,
  CentralizedDisplayAndSignage,
  EnergyManagementSolutions,
  Intrusion,
  OccupancyMonitoring,
  SmartIOT,
} from "../Components/SubComponents/Product/index";

export const productHeroPageDescription =
  "We tailor our services to meet your specific needs, whether it's for industrial, commercial, or residential applications.";

export const productHeroPageData = [
  {
    id: "energy-management-solutions",
    title: "Energy Management Solutions (EMS)",
    logo: energyManagementSolutionsLogo,
    component: <EnergyManagementSolutions />,
  },
  {
    id: "cctv",
    title: "CCTV",
    logo: cctvLogo,
    component: <Cctv />,
  },
  {
    id: "intrusion",
    title: "Intrusion",
    logo: instrusionLogo,
    component: <Intrusion />,
  },
  {
    id: "addressable-fire-systems",
    title: "Addressable Fire Systems",
    logo: addressableFireSystemsLogo,
    component: <AddressableFireSystem />,
  },
  {
    id: "smart-iot",
    title: "Smart IOT",
    logo: smartIotLogo,
    component: <SmartIOT />,
  },
  {
    id: "centralized-display-and-signage",
    title: "Centralized Display & Signage",
    logo: centralizedDisplayAndSignageLogo,
    component: <CentralizedDisplayAndSignage />,
  },
  {
    id: "occupancy-monitoring",
    title: "Occupancy Monitoring",
    logo: occupancyMonitoringLogo,
    component: <OccupancyMonitoring />,
  },
];

export const energyManagementSolutionsData = {
  id: "energy-management-solutions",
  title: "Energy Management Solutions ( EMS ) ",
  description:
    "We tailor our services to meet your specific needs, whether it's for industrial, commercial, or residential applications.",
  subtitle1: "KiAM IoT Based Solution For SMART Energy Monitoring",
  subdescription1:
    "Energy monitoring system technique tracks 24/7 real-time energy consumption data so that building power consumption can be managed proficiently. Energy saving and reduce carbon footprints can be possible with energy management software to decrease the energy expenditure from the businesses.",
  benefitsTitle: "Smart & Sustainable Energy Monitoring System Benefits",
  benefits: [
    "Comprehensive solution power monitoring hardware as well as the software integrated with the solutions.",
    "Know your energy expenditure and break-down of energy share of different equipment(’s).",
    "With Data Analytics, analyse Customer pattern through retail analytics.",
    "Measure the exact Energy Saving achieved by each new project implemented.",
    "Centralised real-time monitoring & control.",
    "Energy and temperature data available in real-time.",
    "Historical data analysis, comparison and decision making.",
    "Control of AC with standard operating practices using predictive analysis.",
    "No human error, notification for every inaccuracy.",
    "Report & notification schedules can be created for week, month and year.",
    "Flexibility to manage exceptions locally in premise with audit log.",
    "All maintenance activity tracked centrally.",
    "Effectiveness of maintenance measured by savings in energy.",
    "Mobile app for your personalized experience.",
  ],
};

export const cctvData = {
  id: "cctv",
  title: "CCTV",
  description1: `CCTV is a critical security component for all facilities. Video Surveillance helps identify security incidents and take actions.`,
  description2: `The majority of locations have CCTV installed, however it is not being watched effectively. When needed, footage is most likely unavailable because the DVR/NVR is not powered or the HDD has failed. Other possibilities include repositioning the camera, camouflaging the camera, or the camera not working.`,
  description3: `This can be taken care with KiAM 24 x 7 CCTV monitoring services.`,
  featuresTitle: "Features of CCTV",
  features: [
    "Event based Video surveillance",
    "Night time CCTV monitoring",
    "Evidence support with Event based image and video storage on Cloud",
    "Pattern Analysis for Proactive actions",
    "DVR/ NVR status and recording checks",
    "CCTV Alarm and break down monitoring:",
    "CCTV Camera status",
    "Camera Mask",
    "Camera position shift",
    "CCTV system AMC management",
    "Weekly /Monthly MIS reports for analysis and actions",
  ],
};

export const intrusionData = {
  id: "intrusion",
  title: "",
  description: `CCTV is a critical security component for all facilities. Video Surveillance helps identify security incidents and take actions.The majority of locations have CCTV installed, however it is not being watched effectively. When needed, footage is most likely unavailable because the DVR/NVR is not powered or the HDD has failed. Other possibilities include repositioning the camera, camouflaging the camera, or the camera not working.This can be taken care with KiAM 24 x 7 CCTV monitoring services.`,
  applicationTitle: "Applications",
  applications: [
    "Monitoring of different inputs in ATM such as removal sensors, panic switch, smoke detector to avoid theft of cash and provide adequate safety to customers",
    "Attendance for guard, house keeping, CRA (Cash Replacement Agency) using biometric or card system",
    "CCTV event-based image capturing for easy first level investigation and recording for evidence",
    "OTP (One Time Password) based access control of back room door used for ATM utility equipment storage",
    "HVAC power and energy monitoring for power saving",
    "Control of signage and lobby light with auto scheduling as well as manual control from CMS",
    "ATM lobby temperature monitoring for comfort and energy saving",
    "Centralized monitoring and control/configuration for total control on ATM infrastructure from a security perspective",
    "Centralized monitoring required for workflow management such as alert management, vendor management, ticket management, and meaningful MIS reports",
  ],
  sensorsTitle: "Monitoring Panel - Sensors",
  sensors: [
    "Removal Sensor – ATM, UPS, Cheque Drop Box, Air Conditioner",
    "Vibration Sensor – To avoid ATM machine tampering",
    "Door Open Sensor – ATM Back Room, ATM Front Door",
    "Panic / Duress Button (At Back Room)",
    "Smoke Detector – For fire alarm",
    "Alarm Panel Tamper & Alarm Panel Enclosure Tamper",
    "Glass Break Detector",
    "Motion/ Occupancy Sensor",
    "Power Input – Mains Input (ON / OFF), Panel Battery Low",
    "Power Output - UPS Output (ON / OFF)",
    "Monitoring of Environment/ Analog Inputs like Temperature and Humidity",
    "Monitoring of AC Power",
  ],
  outputsTitle: "Monitoring Panel - Outputs",
  outputs: [
    "Hooter to alert Guard",
    "Network Switch Restart",
    "Access Door Open for Front and Rear Door",
    "Door Open for Cheque Drop Box",
    "Door Open for ATM Cash Deposit",
    "AC Power Control",
    "Light Control",
    "Buzzer for Door Open Too Long",
    "CCTV Event-Based Image Capture and Motion-Based Storage",
  ],
  image1: iotBasedIntrusionImage,
  image2: intrusionImage,
};

export const addressableFireSystemData = {
  id: "addressable-fire-systems",
  title: "IOT based Fire Alarm System",
  description: `Our fire monitoring system entails remote monitoring of fire, smoke alarm, and system health by connecting fire panels to IoT network connective devices and pushing data to cloud hosted software, with a 24*7 centralized monitoring station providing quick responses.`,
  featuresTitle: "Sensors",
  features: [
    "Removal Sensor - ATM, UPS, Cheque Drop Box, Air Conditioner",
    "Vibration Sensor To avoid ATM machine tampering",
    "Door Open Sensor Door Open/close monitoring",
    "Panic / Duress Button",
    "Smoke Detector For fire alarm",
    "Alarm panel tamper & Alarm panel Enclosure Tamper",
    "Glass Break Detector",
    "Motion/ Occupancy Sensor",
    "Power Input Mains Input (ON / OFF), Panel Battery Low",
    "Power Output - UPS Output (ON / OFF)",
    "Monitoring of environment/ Analog inputs like Temperature and Humidity",
    "Monitoring of AC power",
    "Monitoring Panel Sensors",
    "Separation Systems",
    "Coming Soon",
    "Our Services",
  ],
};

export const smartIOTData = {
  id: "smart-iot",
  title: "Smart IOT",
  description: `15ROI less than 1 year to 20 % energy saving, Predictive maintenance  management, Comfort to customer, Green building compliant, Save power save environment, Control over store assets  centrally Improved bottom line.

Remove space in energy saving solution description`,
  featuresTitle: "Applications",
  features: [
    "The IoT Platform comes with a fully configurable application.",
    "Multiple Utility Support",
    "Dashboards for insights",
    "Multi-level hierarchy",
    "Live performance monitoring",
    "Report builder support",
    "AMC Task Management",
    "Bill Management",
    "Graphical analysis",
    "Standard templates for energy management",
    "Alerts and warnings",
    "Preventive alerts and warnings",
  ],
};

export const centralizedDisplayAndSignageData = {
  id: "centralized-display-and-signage",
  title: "Centralized Display and Signage",
  description: `With Digital Signage you can put a TV at the place of the poster, connect a device to the TV and then through the software you can publish all your promotions in the form of pictures, slideshows, videos, widgets, RSS, Live TV, Metrics. With the help of the software you can control and change the promotion any time you want and any how you want. You can access the software from anywhere where you have the internet. eg. You are in New York and your TV is in California. You can change your content while sitting in New York and it will get changed and display on the TV in California.`,
  featuresTitle: "Applications",
  features: [
    "Play your menu.",
    "Schedule your menu according to breakfast, lunch, dinner.",
    "Schedule according to particular day and time e.g. schedule sales template in January for promotion in March.",
    "Change Menu anytime.",
    "Plus a lot more options like scheduling, spreadsheets, clock etc.",
  ],
};

export const occupancyMonitoringData = {
  id: "occupancy-monitoring",
  title: "Occupancy Monitoring",
  description: `Occupancy monitoring systems have always offered valuable insight to business owners – but, following shifts in working habits and attitudes, people counting technology is an essential upgrade for any company.`,
  featuresTitle: "Benefits of occupancy monitoring",
  features: [
    "Whether occupancy monitoring sensors are deployed in reception areas, shop floors, meeting rooms, or throughout an entire office complex; occupancy monitoring systems provide valuable insight into exactly how spaces are used.",
    "This insight enables better decision-making for the allocation of space, staff, and resources; helping businesses run more efficiently and safely.",
    "Occupancy monitoring systems provide access both to real-time and historical data. While real-time occupancy monitoring data provides instant information on current usage, historical data reveals trends in occupancy over days, weeks, months, and years.",
    "Occupancy monitoring systems are a rich source of information that can be put to immediate practical use in a number of ways.",
  ],
};
