import React from "react";
import { SuccessStoriesComponent } from "../Components/SubComponents/SuccessStories";

const SuccesStoriesPage = () => {
  return (
    <>
      <SuccessStoriesComponent />
    </>
  );
};

export default SuccesStoriesPage;
