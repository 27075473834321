import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import { ProductDetails } from "./Components/SubComponents/Product";
import {
  AboutPage,
  CareerPage,
  HomePage,
  ProductPage,
  ServicePage,
} from "./Pages";
import { ServiceDetails } from "./Components/SubComponents/Service";
import SuccesStoriesPage from "./Pages/SuccesStoriesPage";
import { EnquireNowPopup } from "./Components/ReusableComponents";
import ArticleDetails from "./Components/SubComponents/Home/ArticleDetails";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route key="index" path="/" element={<HomePage />} />
          <Route key="layout" path="/" element={<Layout />}>
            <Route key="products" path="products" element={<Outlet />}>
              <Route key="product" index element={<ProductPage />} />
              <Route
                key="product-details"
                path="product-details"
                element={<ProductDetails />}
              />
            </Route>
            {/* <Route key="career" path="career" element={<CareerPage />} /> */}
            <Route
              key="success-stories"
              path="success-stories"
              element={<SuccesStoriesPage />}
            />
            <Route key="services" path="services" element={<Outlet />}>
              <Route key="service" index element={<ServicePage />} />
              <Route
                key="service-details"
                path="service-details"
                element={<ServiceDetails />}
              />
            </Route>
            <Route key="articles" path="articles" element={<Outlet />}>
              {/* <Route key="articles" index element={<Navigate to={"/"} />} /> */}
              <Route key="article-details" index element={<ArticleDetails />} />
            </Route>
            <Route key="about" path="about" element={<AboutPage />} />
          </Route>
        </Routes>
        <EnquireNowPopup />
      </BrowserRouter>
    </>
  );
};

export default App;
