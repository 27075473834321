import React, { useState } from "react";
import { Heading } from "../../ReusableComponents";
import { pioneeringBrandsData } from "../../../Constants";

const Clients = () => {
  const [active, setActive] = useState("metro");
  return (
    <div className="w-full flex-center flex-col gap-[2rem] my-[2rem]">
      <Heading
        title={"Clients that trust us"}
        className={
          "text-secondary uppercase font-semibold text-[1.6rem] text-center "
        }
      />
      <div className="flex-center flex-wrap border border-gray-200 bg-[#F5F5F5] h-auto rounded-sm px-[2rem]">
        {pioneeringBrandsData?.map((item, index) => (
          <div
            key={index}
            className={`h-full text-[0.9rem] cursor-pointer py-[1rem] px-[1rem] mx-[1rem] ${
              active === item?.id ? "bg-white" : ""
            } `}
            onClick={() => setActive(item?.id)}
          >
            {item?.categoryTitle}
          </div>
        ))}
      </div>
      <div className="flex-center flex-wrap gap-[2rem] xl:gap-[2rem] w-[60%] xl:w-[40%] ">
        {pioneeringBrandsData
          ?.find((item) => item?.id === active)
          ?.companies?.map((company, index) => (
            <div className="flex-center h-[2.6rem] mx-[0.5rem] ">
              <img
                src={company?.company_logo}
                alt={company?.company_name}
                className="size-full object-contain "
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Clients;
