import React from "react";
import { tenthAnniversaryImage } from "../../../Assets";
import { homeHeroDescription } from "../../../Constants";

const HomeSlide2 = () => {
  return (
    <div className=" flex-col flex-center mb-[1rem] gap-[0.6rem] mt-[2rem] ">
      <img
        src={tenthAnniversaryImage}
        alt=""
        className="object-contain w-[20rem] sm:w-[24rem]"
      />
      <div className="w-[90%] sm:w-[60%] xl:w-[39%] flex justify-center items-center flex-wrap mt-[1rem] ">
        <span className="text-white text-center text-[0.833rem]">
          {homeHeroDescription}
        </span>
      </div>
    </div>
  );
};

export default HomeSlide2;
