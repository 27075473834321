import React from "react";
import {
  energySolutionDiagramImage,
  iesgIoneImage,
  monitoringDashboardImage,
} from "../../../../Assets";
import {
  Description,
  EnquireNowButton,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { energyManagementSolutionsData } from "../../../../Constants";

const EnergyManagementSolutions = () => {
  const {
    id,
    description,
    title,
    subdescription1,
    subtitle1,
    benefits,
    benefitsTitle,
  } = energyManagementSolutionsData;

  return (
    <div id={id} className="flex-center flex-col w-full gap-[2rem] mt-[1rem] ">
      <div className="flex justify-center items-start gap-[2rem] flex-wrap ">
        <div className="flex-center w-[14rem]">
          <img
            src={iesgIoneImage}
            alt="IESG IONE"
            className="size-full object-contain"
          />
        </div>
        <div className="flex-start flex-col gap-[1rem] max-w-[24rem] ">
          <Heading title={title} className={"text-[2rem]"} />
          <Description
            text={description}
            className={"max-w-[24rem] text-start"}
          />
          <EnquireNowButton
            className={"text-[0.68rem] px-[4rem] py-[0.3rem]  "}
          />
        </div>
      </div>
      <Heading title={subtitle1} className={"text-primary text-center "} />
      <Description
        text={subdescription1}
        className={"text-center max-w-[24rem] xl:max-w-[40rem]"}
      />
      <div className="flex-center w-[100%] lg:w-[75%] ">
        <img
          src={energySolutionDiagramImage}
          alt=""
          className="object-contain size-full"
        />
      </div>
      <FeaturePoints title={benefitsTitle} points={benefits} />
    </div>
  );
};

export default EnergyManagementSolutions;
