import React, { useEffect } from "react";
import {
  HomeHero,
  AboutCompany,
  ProductsIntro,
  PlatformsIntro,
  TechnologyBlock,
  PioneeringBrands,
  CaseStudies,
  OemAndTechnologyPartners,
  BackgroundSideComponent,
  OurValues,
  HomeProducts,
  Clients,
  OemPartners,
} from "./index";
import { leftBgImage, rightBgImage } from "../../../Assets";
import { useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import ArticlesForYou from "./ArticlesForYou";

const HomeComponent = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div className="w-full flex-col flex-center gap-[2rem]  ">
      {/* <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[4%]"}
      /> */}
      <HomeHero />
      {/* <BackgroundSideComponent
        image={rightBgImage}
        className={"right-0 top-[4%]"}
      /> */}
      <div className="flex-center flex-col w-full app gap-[2rem]">
        <AboutCompany />
        <OurValues />
        {/* <ProductsIntro /> */}
        <HomeProducts />
        <PlatformsIntro />
        <TechnologyBlock />
        {/* <PioneeringBrands /> */}
        <Clients />
        <OemPartners />
        {/* <OemAndTechnologyPartners /> */}
        {/* <BackgroundSideComponent
          image={leftBgImage}
          className={"left-0 top-[81%]"}
        /> */}
        <CaseStudies />
        <ArticlesForYou />
        <Footer />
      </div>
    </div>
  );
};

export default HomeComponent;
