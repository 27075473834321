import {
  aboutPageDescription,
  aboutPageData,
  whoWeAreDescription1,
  whoWeAreDescription2,
  whoWeAreServicesData,
} from "./AboutPageConstants";
import {
  companyGrowthFigures,
  productsIntroData,
  pioneeringBrandsData,
  caseStudiesDescription,
  oemAndTechnologyPartnersData,
  aboutCompanyDescription1,
  aboutCompanyDescription2,
  ourValuesArray,
  articlesForYouArray,
} from "./HomePageConstants";
import {
  productHeroPageDescription,
  productHeroPageData,
  energyManagementSolutionsData,
  cctvData,
  addressableFireSystemData,
  smartIOTData,
  centralizedDisplayAndSignageData,
  occupancyMonitoringData,
  intrusionData,
} from "./ProductPageConstants";
import {
  serviceHeroPageData,
  intrusionAndFireAlertingData,
  esurveillanceData,
  cctvSolutionsAndAuditsData,
  esgSolutionsData,
  twentyFourBySevenCommandControlData,
} from "./ServicePageConstants";
import {
  successStoriesCategories,
  successStoriesData,
} from "./SuccessStoriesConstants";

export const homeHeroTitle = "kiam intl PVT. LTD. your Technology Partner";
export const homeHeroDescription =
  "With an agile approach and outstanding solutions in IT that we customize for our clients, we create an unbeatable, unique experience in technology";

export const ourValuesDescription =
  "We are concerned about what truly matters, and that sets us apart from the rest. ";

export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "products",
    title: "Products",
  },
  {
    id: "about",
    title: "About Us",
  },
  // {
  //   id: "career",
  //   title: "Careers",
  // },
];

export const footerLinks = [
  {
    id: "company",
    title: "Company",
    sublinks: [
      {
        sublinkid: "about",
        sublinktitle: "About",
      },
      {
        sublinkid: "success-stories",
        sublinktitle: "Success Stories",
      },
      // {
      //   sublinkid: "blog",
      //   sublinktitle: "Blog",
      // },
      // {
      //   sublinkid: "career",
      //   sublinktitle: "Careers",
      // },
    ],
  },
  {
    id: "products",
    title: "Products",
    sublinks: [
      { sublinkid: "cctv", sublinktitle: "CCTV" },
      {
        sublinkid: "energy-management-solutions",
        sublinktitle: "Energy Monitoring & Saving",
      },
      {
        sublinkid: "addressable-fire-systems",
        sublinktitle: "Sensors",
      },
      // {
      //   sublinkid: "vending-machine",
      //   sublinktitle: "Other",
      // },
    ],
  },
  {
    id: "services",
    title: "Platforms",
    sublinks: [
      {
        sublinkid: "24x7-command-and-control",
        sublinktitle: "24 x 7 Command & Control",
      },
      {
        sublinkid: "intrusion-and-fire-alerting",
        sublinktitle: "Intrusion and Fire Alerting",
      },
      {
        sublinkid: "e-surveillance",
        sublinktitle: "E-Surveillance",
      },
      {
        sublinkid: "cctv-based-ai-solution-and-audits",
        sublinktitle: "CCTV based AI solution & audits",
      },
      // {
      //   sublinkid: "network-management",
      //   sublinktitle: "Network Management",
      // },
      // {
      //   sublinkid: "data-analysis",
      //   sublinktitle: "Data Analysis",
      // },
      // {
      //   sublinkid: "network-connectivity",
      //   sublinktitle: "Network Connectivity",
      // },
      // {
      //   sublinkid: "esg-solutions",
      //   sublinktitle: "ESG Solutions",
      // },
    ],
  },
  // {
  //   id: "join-us",
  //   title: "Join Us",
  //   sublinks: [
  //     {
  //       sublinkid: "",
  //       sublinktitle:
  //         "Join millions of people who build a fully integrated sales and maketing solution.",
  //     },
  //   ],
  // },
];

export const enquiryMailId = "enquiry@kiam.in";
export const phone1 = "020-67479668";
export const phone2 = "7045161874";

export const homeHeroDescriptionArray = [
  "24 sensors (Potential Free)",
  "32 smoke and fire sensors",
  "Optional temperature and energy monitoring",
  "3rd party equipment integration",
  "CCTV integration",
  "Built-in Battery",
  "IP based IoT device",
];

export const addressArray = [
  {
    id: "head-office",
    title: "Head Office",
    address:
      "1stFloor, Block No. 27, Electronic Co-op Estate, Satara Road, Swargate, Pune - 411009",
    link: "https://maps.app.goo.gl/KtcGSQkcsJxXpwTDA",
  },
  {
    id: "mumbai-office",
    title: "Mumbai Office",
    address:
      "708-A, Ellora Olearise, Plot no. 786, TTC Industrial Area, Kopar Khairane MIDC, Navi Mumbai 400710",
    link: "https://maps.app.goo.gl/YUwMohZCNNv5ULWn6",
  },
  {
    id: "bangalore-office",
    title: "Bangalore Office",
    address:
      'No.205A, 2nd Floor, "Annexe" Block, Brigade MM, K R Road, Jaynagar 7th Block, Bengaluru - 560070',
    link: "https://maps.app.goo.gl/ZRThyJNtt6U8Vxgv7",
  },
];

export {
  companyGrowthFigures,
  productsIntroData,
  aboutPageDescription,
  aboutPageData,
  whoWeAreDescription1,
  whoWeAreDescription2,
  whoWeAreServicesData,
  productHeroPageDescription,
  productHeroPageData,
  energyManagementSolutionsData,
  cctvData,
  addressableFireSystemData,
  smartIOTData,
  centralizedDisplayAndSignageData,
  occupancyMonitoringData,
  serviceHeroPageData,
  intrusionAndFireAlertingData,
  esurveillanceData,
  cctvSolutionsAndAuditsData,
  esgSolutionsData,
  pioneeringBrandsData,
  caseStudiesDescription,
  successStoriesCategories,
  successStoriesData,
  oemAndTechnologyPartnersData,
  intrusionData,
  twentyFourBySevenCommandControlData,
  aboutCompanyDescription1,
  aboutCompanyDescription2,
  ourValuesArray,
  articlesForYouArray,
};
