import React from "react";
import { Description, Heading } from "../../ReusableComponents";
import { serviceHeroPageData } from "../../../Constants";
import { Link } from "react-router-dom";
import { BackgroundSideComponent } from "../Home";
import { leftBgImage, rightBgImage } from "../../../Assets";

const ServiceHero = () => {
  return (
    <div className="w-full flex-center flex-col gap-[2rem] mt-[2rem] ">
      <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[6%]"}
      />
      <Heading title={"Services We Offer...."} />
      <div className=" flex-center w-[100%] xl:w-[50%]">
        <Description
          text={
            "We tailor our services to meet your specific needs, whether it's for industrial, commercial, or residential applications."
          }
        />
      </div>

      <div className="flex items-start justify-center flex-wrap gap-[1rem] ">
        {serviceHeroPageData?.map((service, index) => (
          <Link
            to={`/services/service-details?service=${service?.id}`}
            key={index}
            className=" flex-center flex-col gap-[0.6rem] w-[8rem] min-h-[5.5rem] border border-black rounded-2xl py-3 px-3 shadow-2xl shadow-gray-200 hover:scale-105 duration-300 ease-in-out hover:bg-gray-200"
          >
            <Description text={service?.title} className={"text-[0.75rem]"} />
          </Link>
        ))}
      </div>
      <BackgroundSideComponent
        image={rightBgImage}
        className={"right-0 top-[50%]"}
      />
    </div>
  );
};

export default ServiceHero;
