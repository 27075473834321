import React from "react";
import { twentyFourBySevenCommandControlData } from "../../../../Constants";
import {
  Description,
  EnquireNowButton,
  Heading,
} from "../../../ReusableComponents";

const TwentyFourBySevenCommandControl = () => {
  const {
    id,
    features,
    description,
    image1,
    image1Description,
    subdescription1,
    subtitle1,
    title,
  } = twentyFourBySevenCommandControlData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem]">
      <Heading title={title} />
      <Description text={description} />
      <div className="flex justify-center items-start gap-[2rem] flex-wrap mt-[1rem] ">
        <div className="w-[14rem] flex-center flex-col gap-[2rem] ">
          <img
            className="size-full object-contain"
            src={image1}
            alt="Intrusion and Fire Alerting Panel"
          />
          <Description text={image1Description} className={"font-bold"} />
        </div>
        <div className="flex-center lg:flex-start flex-col gap-[2rem] max-w-[24rem] xl:max-w-[28rem]">
          <Heading
            title={subtitle1}
            className={"text-primary text-[1.1rem] leading-tight "}
          />
          <hr className="w-full h-[2px] bg-black " />
          <Description text={subdescription1} className={"text-start"} />
        </div>
        {/* <div className="flex-center gap-[2rem] flex-wrap ">
          <FeaturePoints
            title={featuresTitle}
            points={features}
            showEnquireButton
          />
        </div> */}
        <div className="flex-col flex-center w-full">
          <div
            className={` flex-start flex-col w-[90%] md:w-[90%] xl:w-[78%] 2xl:w-[68%]`}
          >
            <EnquireNowButton
              className={"text-[0.68rem] mt-[2rem] px-[4.5rem]"}
            />
            <div
              className={`flex-start flex-col gap-[0.5rem] border-l border-black pl-[2rem] pt-[2rem] ml-[0.6rem] lg:ml-[2rem] `}
            >
              <Heading
                title={title}
                className={"text-primary mb-[1rem] text-center"}
              />
              <div className="flex-start flex-col gap-[1rem] ">
                {Object?.keys(features)?.map((key, index) => {
                  return (
                    <li
                      key={index}
                      className="text-[0.85rem] sm:text-[0.78rem]  flex-start flex-col gap-[0.5rem] "
                    >
                      <span className="flex-row flex-center gap-[0.4rem]">
                        <div className="size-1 rounded-full bg-black" /> {key} :
                      </span>
                      <span>{features?.[key]}</span>
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentyFourBySevenCommandControl;
