import React, { useRef } from "react";
import {
  Button,
  Description,
  Heading,
  TransparentButton,
} from "../../ReusableComponents";
import { caseStudiesDescription } from "../../../Constants/HomePageConstants";

// Swiper Imports
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, EffectCards } from "swiper/modules";
import { caseStudyImage } from "../../../Assets";
import { Link } from "react-router-dom";

const CaseStudies = () => {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  return (
    <div className="flex-center w-full flex-col gap-[1rem] my-[2rem] mt-[3rem]">
      <div className="w-full flex-center flex-wrap gap-[2rem]">
        <div className="flex-start flex-col gap-[0.5rem] max-w-[15rem] ">
          {/* <TransparentButton title={"CASE STUDIES"} /> */}
          <Heading
            className={"text-secondary text-[1.6rem] "}
            title={"Success Stories"}
          />
          <Description
            text={caseStudiesDescription}
            className={
              " text-start text-[0.70rem] tracking-wide text-secondary "
            }
          />
          <div className="flex-start gap-[1rem] mt-[0.5rem]">
            <Button
              className="rounded-2xl px-4 uppercase text-[0.9rem] text-semibold py-1 flex-center shadow-md shadow-gray-300"
              onClick={handlePrev}
            >
              Back
            </Button>
            <button
              className="rounded-2xl px-4 uppercase text-[0.9rem] text-semibold py-1 flex-center text-white bg-[#2938BB] shadow-md shadow-gray-300"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
          <Link
            to={"/success-stories"}
            className="text-[0.8rem] mt-[1rem] cursor-pointer"
          >
            {`Show All`}&nbsp;&nbsp;{` ->`}
          </Link>
        </div>
        <div className="w-[100%] md:w-[62%]">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            loop
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            modules={[EffectCards]}
            className="w-full rounded-xl shadow-lg shadow-gray-400"
            ref={swiperRef}
          >
            <SwiperSlide>
              <img
                src={caseStudyImage}
                alt=""
                className="object-contain rounded-xl"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
