import React from "react";
import { cctvImage } from "../../../../Assets";
import {
  Description,
  EnquireNowButton,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { cctvData } from "../../../../Constants";

const Cctv = () => {
  const {
    id,
    description1,
    features,
    featuresTitle,
    title,
    description2,
    description3,
  } = cctvData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem] mt-[1rem] ">
      <div className="flex justify-center items-start gap-[2rem] flex-wrap ">
        <div className="flex-center flex-col w-[14rem]">
          <img
            src={cctvImage}
            alt="CCTV"
            className="size-full object-contain"
          />
          {/* <EnquireNowButton className={"text-[0.68rem] mt-[2rem] "} /> */}
        </div>
        <div className="flex-start flex-col gap-[1rem] max-w-[24rem] ">
          <Heading title={title} className={"text-[2rem]"} />
          <div className="flex-start flex-col gap-[0.4rem]">
            <Description
              text={description1}
              className={"max-w-[24rem] text-start"}
            />
            <Description
              text={description2}
              className={"max-w-[24rem] text-start"}
            />
            <Description
              text={description3}
              className={"max-w-[24rem] text-start"}
            />
          </div>
        </div>
      </div>
      <div className="flex-start md:flex-start gap-[2rem] flex-wrap w-[100%] md:w-[80%] lg:w-[65%] xl:w-[65%] 2xl:w-[60%] ">
        <FeaturePoints
          title={featuresTitle}
          points={features}
          showEnquireButton
        />
      </div>
    </div>
  );
};

export default Cctv;
