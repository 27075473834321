import React from "react";
import {
  Description,
  EnquireNowButton,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { centralizedDisplayAndSignageData } from "../../../../Constants";
import { centralizedDisplayAndSignageImage } from "../../../../Assets";

const CentralizedDisplayAndSignage = () => {
  const { id, description, features, featuresTitle, title } =
    centralizedDisplayAndSignageData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[2rem] mt-[1rem] ">
      <div className="flex-center flex-col w-[16rem] lg:w-[24rem] ">
        <img
          src={centralizedDisplayAndSignageImage}
          alt="Centralized Display & Signage"
          className="size-full object-contain"
        />
      </div>
      <div className="flex-center flex-col gap-[1rem]">
        <Heading title={title} className={"text-[2rem] w-full text-center"} />
        <Description
          text={description}
          className={"max-w-[24rem] md:max-w-[36rem] text-center"}
        />
        <EnquireNowButton
          className={"text-[0.68rem] px-[4.5rem] py-[0.4rem]"}
        />
      </div>
      <FeaturePoints
        title={featuresTitle}
        points={features}
        className={"max-w-[75%]"}
      />
    </div>
  );
};

export default CentralizedDisplayAndSignage;
