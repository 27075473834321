import {
  bankBranchesImage,
  bankImage,
  commercialSpaceImage,
  happyClientsImage,
  itItesImage,
  manufacturingImage,
  retailShopImage,
  warehouseImage,
} from "../Assets";

export const aboutPageDescription =
  "With an agile approach and outstanding solutions in IT that we customize for our clients, we create an unbeatable, unique experience in technology";

export const aboutPageData = [
  {
    id: "bank-branches",
    title: "Bank Branches",
    image: bankBranchesImage,
    count: 3500,
  },
  {
    id: "it-ites",
    title: "IT/ITES",
    image: itItesImage,
    count: 45,
  },
  {
    id: "manufacturing",
    title: "Manufacturing",
    image: manufacturingImage,
    count: 35,
  },
  {
    id: "commercial-places",
    title: "Commercial Places",
    image: commercialSpaceImage,
    count: 500,
  },
  // {
  //   id: "deployment-across-india",
  //   title: "Deployment Across India",
  //   image: happyClientsImage,
  //   count: 8000,
  // },
  // {
  //   id: "warehouse",
  //   title: "Warehouse",
  //   image: warehouseImage,
  //   count: 40,
  // },
  // {
  //   id: "retail-stores",
  //   title: "Retail Stores",
  //   image: retailShopImage,
  //   count: 3500,
  // },
];

export const whoWeAreDescription1 =
  "With an agile approach and outstanding solutions in IT that we customize for our clients, we create an unbeatable, unique experience in technology ";
export const whoWeAreDescription2 =
  "Given our years of experience and expertise in niche areas of IT, our partnership is an invaluable asset to organizations who choose to work with us. We truly understand IT! And that is the foundation of how we are driven to deliver the unique experience that our clients usually look for.";

export const whoWeAreServicesData = [
  {
    id: "access-control",
    title: "Access Control",
  },
  {
    id: "amc-services",
    title: "AMC Services",
  },
  {
    id: "centralized-remote-monitoring",
    title: "Centralized Remote Monitoring",
  },
  {
    id: "energy-monitoring-and-saving",
    title: "Energy Monitoring & Saving",
  },
];
