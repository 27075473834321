import React, { useState } from "react";
import Heading from "../../ReusableComponents/Heading";
import { Description } from "../../ReusableComponents";
import { caseStudiesDescription } from "../../../Constants/HomePageConstants";
import {
  successStoriesCategories,
  successStoriesData,
} from "../../../Constants";
import SuccessCard from "./SuccessCard";

const SuccessStoriesHero = () => {
  const [activeCategory, setActiveCategory] = useState("retail");

  return (
    <div className="flex-center flex-col gap-[1rem] mt-[2rem]">
      <Heading
        className={"text-secondary text-[1.6rem] text-center "}
        title={"Success Stories"}
      />
      <Description
        text={caseStudiesDescription}
        className={"text-secondary text-start text-center "}
      />
      <div className="flex-center w-full flex-wrap gap-[1rem]">
        <span className={"text-primary text-[1rem]  font-semibold"}>
          Change Category
        </span>
        <div className="flex-center flex-wrap gap-[1rem]">
          {successStoriesCategories.map((category) => (
            <div
              key={category?.id}
              className={`flex-center px-3 rounded-md cursor-pointer text-[0.75rem] py-1 ${
                activeCategory === category?.id ? "border border-primary" : ""
              } `}
              onClick={() => setActiveCategory(category?.id)}
            >
              {category?.title}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-center flex-col gap-[2rem] w-full mt-[2rem]">
        {successStoriesData
          ?.find((item) => item?.id === activeCategory)
          ?.stories?.map((story, index) => (
            <SuccessCard key={index} story={story} />
          ))}
      </div>
    </div>
  );
};

export default SuccessStoriesHero;
