import { Description, Heading } from "../../ReusableComponents";
import {
  productHeroPageData,
  productHeroPageDescription,
} from "../../../Constants";
import { Link } from "react-router-dom";
import React from "react";
import { BackgroundSideComponent } from "../Home";
import { leftBgImage, rightBgImage } from "../../../Assets";

const ProductHero = () => {
  return (
    <div className="w-full flex-center flex-col gap-[1.6rem] mt-[2rem] ">
      <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[4%]"}
      />
      <Heading title={"Iot Products We Offer...."} />
      {/* <div className=" flex-center w-[100%] xl:w-[50%]">
        <Description text={productHeroPageDescription} />
      </div> */}
      <div className="flex items-start justify-center flex-wrap gap-[1rem] ">
        {productHeroPageData?.map((product, index) => (
          <Link
            to={`/products/product-details?product=${product.id}`}
            key={index}
            className=" flex-center flex-col gap-[0.6rem] w-[8rem] min-h-[8rem] rounded-2xl py-3 px-3 shadow-2xl shadow-gray-300 hover:scale-110 duration-300 ease-in-out hover:bg-gray-200"
          >
            <img
              src={product?.logo}
              alt={product?.title}
              className="w-[2rem] object-contain "
            />
            <span
              className={
                " text-[0.8rem] sm:text-[0.68rem] text-center font-medium "
              }
            >
              {product.title}
            </span>
          </Link>
        ))}
      </div>
      <BackgroundSideComponent
        image={rightBgImage}
        className={"right-0 top-[50%]"}
      />
    </div>
  );
};

export default ProductHero;
