import React from "react";
import { ourValuesArray, ourValuesDescription } from "../../../Constants";
import OurValueCard from "./OurValueCard";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const OurValues = () => {
  useGSAP(() => {
    gsap.fromTo(
      ".our-values-card",
      {
        x: -250,
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        x: 0,
        stagger: {
          amount: 1,
        },
        scrollTrigger: {
          trigger: "#our-values",
        },
      }
    );
  }, []);
  return (
    <div
      className="w-full flex-col flex-center gap-[0.9rem] mt-[1.8rem] "
      id="our-values"
    >
      <div className={"text-center uppercase text-[1.2rem] font-semibold"}>
        Our Values
      </div>
      <hr className="w-[4rem] bg-black text-black h-[2px] " />
      <span className="text-[0.75rem]  leading-tight text-center max-w-[95%]  xl:max-w-[50%] ">
        {ourValuesDescription}
      </span>
      <div className="flex-center flex-col gap-[2rem] ">
        {ourValuesArray?.map(
          (
            { bgImage, icon, description, navigationIcon, orientation, title },
            index
          ) => (
            <OurValueCard
              key={index}
              bgImage={bgImage}
              icon={icon}
              orientation={orientation}
              // description={}
              description={description}
              title={title}
              navigationIcon={navigationIcon}
              className={"our-values-card"}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OurValues;
