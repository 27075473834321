import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { articlesForYouArray } from "../../../Constants";
import { Button } from "../../ReusableComponents";
import { ViewPdf } from "../SuccessStories";

const ArticleDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [searchParams]);

  const handleDownload = (details) => {
    const filePath = details?.pathOfPdf;
    const link = document.createElement("a");
    link.href = filePath;
    link.download = details?.title;
    document.body.appendChild(link);
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div className="flex-center w-full flex-col gap-[1rem] my-[2rem] mt-[3rem]">
      <div className="w-full flex-center flex-col flex-wrap gap-[2rem]">
        <div className="w-[80%] flex-center flex-col gap-[1rem]">
          {/* <img
            src={
              articlesForYouArray.find(
                (item) => item?.id === searchParams.get("article")
              )?.image
            }
            className="object-fill size-full"
            alt=""
          /> */}
          <div className="flex flex-row w-full justify-end items-center px-[0.5rem]">
            {/* <Button
              className={"rounded-2xl px-2 py-1 text-[0.7rem] bg-[#2938BB] "}
              onClick={() =>
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              Preview
            </Button>{" "} */}
            {/* <button
              className="rounded-2xl px-4 text-[0.7rem] text-semibold py-1 flex-center text-white bg-[#2938BB] shadow-md shadow-gray-300"
              onClick={() =>
                handleDownload(
                  articlesForYouArray.find(
                    (item) => item?.id === searchParams.get("article")
                  )
                )
              }
            >
              Download
            </button> */}
          </div>
          <ViewPdf
            pathOfPdf={
              articlesForYouArray.find(
                (item) => item?.id === searchParams.get("article")
              )?.pathOfPdf
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleDetails;
