import React, { useState } from "react";
import { Heading } from "../../ReusableComponents";
import { oemAndTechnologyPartnersData } from "../../../Constants";

const OemPartners = () => {
  const [active, setActive] = useState("surveillance-products");
  return (
    <div className=" w-full 2xl:w-[80rem] flex-center flex-col gap-[2rem] my-[2rem]">
      <Heading
        title={"OEM & Technology Partners"}
        className={
          "text-secondary uppercase font-semibold text-[1.6rem] text-center "
        }
      />
      <div className="flex-center flex-wrap border border-gray-200 bg-[#F5F5F5] h-auto rounded-sm px-[2rem] min-w-[8rem] ">
        {oemAndTechnologyPartnersData?.map((item, index) => (
          <div
            key={index}
            className={`h-full text-[0.8rem] cursor-pointer py-[1rem] px-[1rem]  ${
              active === item?.id ? "bg-white" : ""
            } `}
            onClick={() => setActive(item?.id)}
          >
            {item?.categoryTitle}
          </div>
        ))}
      </div>
      <div className="flex-center flex-wrap gap-[2rem] w-[60%] min-h-[8rem] ">
        {oemAndTechnologyPartnersData
          ?.find((item) => item?.id === active)
          ?.companies?.map((company, index) => (
            <div className="flex-center h-[2rem] mx-[0.5rem] ">
              <img
                src={company?.company_logo}
                alt={company?.company_name}
                className="size-full object-contain "
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default OemPartners;
