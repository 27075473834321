import {
  caseStudyImage,
  croosPointImage,
  crossPointPdf,
  successStoryImage,
  sucessStoryPdf,
} from "../Assets";

export const successStoriesHeroDescription =
  "Empowering Your Financial Journey through Customized Asset Management Services";

export const successStoriesCategories = [
  {
    id: "retail",
    title: "Retail",
  },
  {
    id: "bank",
    title: "Bank",
  },
  {
    id: "warehouse",
    title: "Warehouse",
  },
  {
    id: "telecom",
    title: "Telecom",
  },
  {
    id: "pharma",
    title: "Pharma",
  },
];

export const successStoriesData = [
  {
    id: "retail",
    title: "Retail",
    stories: [
      {
        id: "0",
        title: "We are Xcited to introduce new design and new technology.",
        description:
          "From our ace in the Netherlands, we closely cooperate with local experts. You can rely on an international partner network which o ers high level retail solutions combined with service next door. All of our solutions are designed to minimize loss and maximize store performance With our brand new Xperience platform we are ready for the future. Discover how to get more out of your store.",
        category: "Technology",
        date: "7 June 2024",
        image: croosPointImage,
        timeToRead: "5 min read",
        pathOfPdf: crossPointPdf,
      },
    ],
  },
];
