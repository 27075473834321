import React from "react";
import Heading from "../../ReusableComponents/Heading";
import {
  whoWeAreDescription1,
  whoWeAreDescription2,
  whoWeAreServicesData,
} from "../../../Constants";
import {
  aboutBackgroundImage,
  indiaMapImage,
  iso1Logo,
  iso2Logo,
  iso3Logo,
} from "../../../Assets";
import { Button, EnquireNowButton } from "../../ReusableComponents";

const MapComponent = () => {
  return (
    <div className="w-full flex-col flex-center gap-[1rem]">
      <Heading title={"Who are we ?"} className={"text-primary"} />
      <div className="w-[100%] xl:w-[60%] flex-center flex-col gap-[0.5rem] mt-[1rem]">
        {/* <span className=" text-tertiary text-[0.8rem] max-w-[80%] text-center ">
          {whoWeAreDescription1}
        </span> */}
        <span className=" text-tertiary text-[0.85rem] sm:text-[0.8rem] max-w-[80%] text-center ">
          {whoWeAreDescription2}
        </span>
      </div>

      <div className="flex-center flex-wrap gap-[2rem]">
        <div className="w-[20rem] sm:w-[24rem] flex-center ">
          <img
            className="size-full object-contain"
            src={indiaMapImage}
            alt=""
          />
        </div>
        <div className="flex-start flex-col gap-[1rem] max-w-[18rem]">
          <div className="flex-center gap-[1rem]">
            <img src={iso3Logo} alt="ISO" className="w-[5rem]" />
            <img src={iso2Logo} alt="ISO" className="w-[5rem]" />
            <img src={iso1Logo} alt="ISO" className="w-[5rem]" />
          </div>
          <hr className="w-full h-[3px] bg-gray-500 " />
          <Heading
            title={"PAN INDIA REACH"}
            className={"uppercase text-secondary text-[3rem] "}
          />
        </div>
      </div>
      <div className="relative mt-[4rem] w-full">
        <div className=" w-[20rem] sm:w-[24rem] md:w-[44rem] lg:w-[64rem] 2xl:w-[80rem] flex-center pb-[4rem] md:pb-[0rem] ">
          <img
            className="hidden md:flex size-full object-contain"
            alt=""
            src={aboutBackgroundImage}
          />
          <ul className="absolute -translate-y-1/2 top-1/2 md:flex-start md:flex-col list-disc w-[80%] gap-2 lg:gap-4 p-4 md:p-0 my-4 md:mt-0 hidden md:flex md:justify-start md:items-start">
            {whoWeAreServicesData?.map((item, index) => (
              <li
                key={index}
                className="hidden md:flex text-[1rem] text-white font-semibold flex-center gap-[0.8rem]"
              >
                <div className="size-[8px] rounded-full bg-white" />{" "}
                <span>{item?.title}</span>
              </li>
            ))}
          </ul>
          <ul className="flex-start flex-col list-disc w-full gap-[1rem] lg:gap-4 p-4 pl-8 bg-primary flex md:hidden ">
            {whoWeAreServicesData?.map((item, index) => (
              <li key={index} className="text-[1rem] text-white font-semibold">
                {item?.title}
              </li>
            ))}
          </ul>
          <EnquireNowButton
            className={
              "hidden md:flex absolute top-1/2 -translate-y-1/2 right-[14rem] md:right-[15rem] lg:right-[21rem] 2xl:right-[30rem] "
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
