import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { serviceHeroPageData } from "../../../Constants";
import { BackgroundSideComponent } from "../Home";
import { leftBgImage } from "../../../Assets";

const ServiceDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState(searchParams.get("service") || "");

  const handleClick = (id) => {
    setActive(id);
    setSearchParams({ service: id }, { replace: true });
  };

  useEffect(() => {
    if (searchParams.get("service")) {
      setActive(searchParams.get("service"));
      // let elem = document.getElementById(searchParams.get("service"));
      // if (elem) {
      //   elem.scrollIntoView({ behavior: "smooth", block: "start" });
      //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      // }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [searchParams]);

  return (
    <div className="w-full flex-center flex-col gap-[2rem] mt-[1rem]">
      <div className=" flex items-start justify-center flex-wrap gap-[1rem] w-full bg-[#D9D9D9]/30 ">
        {serviceHeroPageData?.map((service, index) => (
          <div className="relative flex-center flex-col cursor-pointer ">
            <span
              key={index}
              onClick={() => handleClick(service?.id)}
              className={` font-semibold text-[0.85rem] sm:text-[0.7rem] text-center ${
                active === service?.id ? "text-primary" : ""
              } hover:text-primary px-4 py-2 md:py-6 md:max-w-[9rem] lg:max-w-[14rem] `}
            >
              {service.title}
            </span>
            {active === service?.id ? (
              <span className="absolute bottom-0 md:bottom-2 w-full h-[1px] lg:h-[2px] bg-gray-500 "></span>
            ) : null}
          </div>
        ))}
      </div>
      {serviceHeroPageData?.find((item) => item?.id === active)?.component}
      <BackgroundSideComponent
        image={leftBgImage}
        className={"left-0 top-[45%]"}
      />
    </div>
  );
};

export default ServiceDetails;
