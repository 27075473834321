import React from "react";
import {
  addressableFireSystem,
  addressableFireSystemDiagram,
} from "../../../../Assets";
import {
  Description,
  EnquireNowButton,
  FeaturePoints,
  Heading,
} from "../../../ReusableComponents";
import { addressableFireSystemData } from "../../../../Constants";

const AddressableFireSystem = () => {
  const { id, description, features, featuresTitle, title } =
    addressableFireSystemData;

  return (
    <div id={id} className="flex-center flex-col w-full gap-[1rem] mt-[1rem] ">
      <div className="flex justify-center items-start gap-[2rem] flex-wrap ">
        <div className="flex-center flex-col w-[12rem]">
          <img
            src={addressableFireSystem}
            alt="Addressable Fire System"
            className="size-full object-contain"
          />
        </div>
        <div className="flex-start flex-col gap-[1rem] max-w-[24rem] ">
          <Heading title={title} className={"text-[2rem]"} />
          <Description
            text={description}
            className={"max-w-[24rem] text-start"}
          />
        </div>
      </div>
      {/* <div className="flex-center w-[100%] lg:w-[60%] ">
        <img
          src={addressableFireSystemDiagram}
          alt=""
          className="object-contain size-full"
        />
      </div> */}
      <div className="flex-start md:flex-start gap-[2rem] flex-wrap w-[100%] md:w-[85%] xl:w-[70%] 2xl:w-[60%] ">
        <FeaturePoints
          title={featuresTitle}
          points={features}
          image={addressableFireSystemDiagram}
          showEnquireButton
        />
      </div>
    </div>
  );
};

export default AddressableFireSystem;
