import React, { useEffect } from "react";
import { scrollDownIcon } from "../../../Assets";
import { companyGrowthFigures } from "../../../Constants";
import Header from "../../Header/Header";

//Swiper Imports
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { HomeSlide1, HomeSlide2 } from ".";

const HomeHero = () => {
  const handleScrollDown = () => {
    const currentPosition =
      window.scrollY || document.documentElement.scrollTop;
    const newPosition = currentPosition + window.innerHeight;
    window.scrollTo({
      top: newPosition,
      behavior: "smooth",
    });
  };

  const animateValue = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = `${Math.floor(progress * (end - start) + start)}`;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    companyGrowthFigures?.forEach((item) => {
      const obj = document.getElementById(item.id);
      animateValue(obj, 0, item?.count, item?.animation_duration);
    });
  }, []);
  return (
    <div className="relative min-h-auto flex-center flex-col w-[100vw] h-[110vh] min-[650px]:h-[70vh] md:h-[75vh] lg:h-[95vh] xl:h-[100vh] home">
      {/* <img
        src={kiamBackgroundImage}
        alt=""
        className="object-contain w-[20rem] mt-[2rem]"
      /> */}
      <div className="absolute top-4 w-full">
        <Header isHomePage={true} />
      </div>
      <div className=" w-full flex-center flex-col mt-[7.5rem] ">
        <Swiper
          className={`w-full flex items-center justify-center py-[1rem] h-auto `}
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Autoplay, Pagination]}
          pagination={{
            clickable: true,
          }}
          style={{
            "--swiper-pagination-color": "#c0872a",
            "--swiper-pagination-bullet-size": "10px",
            "--swiper-pagination-bullet-inactive-color": "#c0872a",
            "--swiper-pagination-bullet-inactive-size": "6px",
          }}
        >
          <SwiperSlide className="mb-[1rem]">
            <HomeSlide1 id={`slider`} />
          </SwiperSlide>
          <SwiperSlide>
            <HomeSlide2 id={`slider`} />
          </SwiperSlide>
        </Swiper>
        {/* <TransparentButton title={"NUMBERS"} /> */}
        <div className="flex-col flex-center w-full gap-[1.5rem] mt-[3.2rem]">
          <div className="flex-row flex-center gap-[2rem] flex-wrap ">
            {companyGrowthFigures?.map((item, index) => (
              <div key={index}>
                <div className="flex-col flex-center gap-[0.5rem] min-w-[8rem] lg:min-w-[10rem] ">
                  {/* <hr className="w-full mb-[0.8rem]" /> */}
                  <div className="flex-center">
                    <span
                      id={item?.id}
                      className="text-white text-[1.75rem] font-medium leading-8"
                    >
                      {item.count}
                    </span>
                    <span className="text-white text-[1.75rem] font-medium pb-[0.3rem] leading-8">
                      +
                    </span>
                  </div>
                  <span className="text-[0.75rem] text-white opacity-50 ">
                    {item.title}
                  </span>
                  {/* <hr className="w-full mt-[0.8rem]" /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <img
          onClick={handleScrollDown}
          src={scrollDownIcon}
          alt=""
          className="object-contain cursor-pointer mt-[2.5rem] mb-[1rem] "
        />
      </div>
    </div>
  );
};

export default HomeHero;
