import React from "react";

const BackgroundSideComponent = ({ className, image }) => {
  return (
    <div className={`absolute -z-[99] ${className}  `}>
      <img
        src={image}
        alt=""
        className="object-contain w-[3rem] md:w-[6rem] xl:w-[9rem]"
      />
    </div>
  );
};

export default BackgroundSideComponent;
