import React from "react";
import ProductComponent from "../Components/SubComponents/Product/ProductComponent";

const ProductPage = () => {
  return (
    <div>
      <ProductComponent />
    </div>
  );
};

export default ProductPage;
