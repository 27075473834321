import React from "react";
import { blackNavigationIcon } from "../../../Assets";
const OurValueCard = ({
  bgImage,
  icon,
  orientation,
  title,
  description,
  navigationIcon,
  className,
}) => {
  return (
    <div className={`flex-center flex-wrap w-full relative ${className}`}>
      <div className=" hidden md:block w-[46rem]">
        <img src={bgImage} className="object-contain size-full" alt="" />
      </div>
      <div
        className={`absolute inset-0 hidden md:flex md:justify-center md:items-center flex-wrap ${
          orientation === "left" ? "flex-row" : "flex-row-reverse"
        } gap-[2rem]`}
      >
        <div className="w-[8.3rem]">
          <img src={icon} alt="" className="object-contain size-full" />
        </div>
        <div
          className={`${
            orientation === "left" ? "flex-start" : "flex-end"
          }  flex-col gap-[0.2rem] max-w-[45%]`}
        >
          <div
            className={`w-full flex justify-start flex-nowrap  items-center gap-[0.8rem] ${
              orientation === "left" ? "flex-row" : "flex-row-reverse"
            } `}
          >
            <img
              src={navigationIcon}
              className="size-[1.4rem] object-contain"
              alt=""
            />
            <span
              className={` ${
                orientation === "left" ? "text-white" : "text-black"
              } text-[1.6rem]`}
            >
              {title}
            </span>
          </div>
          <span
            className={` ${
              orientation === "left" ? "text-white" : "text-black"
            } text-[0.60rem] text-justify`}
          >
            {description}
          </span>
        </div>
      </div>
      <div className="flex justify-center flex-wrap items-center md:hidden gap-[1rem] ">
        <div className="w-[8.3rem]">
          <img src={icon} alt="" className="object-contain size-full" />
        </div>
        <div className={`flex-start  flex-col gap-[0.2rem] w-[85%] `}>
          <div
            className={`w-full flex justify-start flex-nowrap items-center gap-[0.8rem] `}
          >
            <img
              src={blackNavigationIcon}
              className="size-[1.4rem] object-contain"
              alt=""
            />
            <span className={`text-[1.6rem]`}>{title}</span>
          </div>
          <span className={` text-[0.60rem] text-justify`}>{description}</span>
        </div>
      </div>
    </div>
  );
};

export default OurValueCard;
