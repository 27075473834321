import React from "react";
import {
  aboutCompanyDescription1,
  aboutCompanyDescription2,
} from "../../../Constants";
import { Link } from "react-router-dom";

const AboutCompany = () => {
  return (
    <div className="w-full flex-col flex-center gap-[0.9rem] mt-[1.8rem] ">
      <div className={"text-center uppercase text-[1.2rem] font-semibold"}>
        About Our Company
      </div>
      <hr className="w-[5rem] bg-black text-black h-[2px] " />
      <div className="flex-center flex-col gap-[0.8rem] mt-[0.6rem]">
        <span className="text-[0.75rem]  leading-tight text-center max-w-[95%]  xl:max-w-[50%] ">
          {aboutCompanyDescription1}
        </span>
        <span className="text-[0.75rem] leading-tight text-center max-w-[95%]  xl:max-w-[52%] ">
          {aboutCompanyDescription2}
        </span>
      </div>
      <span className=" cursor-pointer text-[0.7rem] font-semibold mt-[0.4rem] text-primary ">
        <Link to={"/about"}>
          {`Learn More`}&nbsp;&nbsp;&nbsp;{`->`}
        </Link>
      </span>
    </div>
  );
};

export default AboutCompany;
