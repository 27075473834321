import React, { useEffect } from "react";
import { AboutHero, MapComponent } from "./index";
import { useLocation } from "react-router-dom";

const AboutComponent = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div className="w-full flex-col flex-center gap-[4rem] ">
      <AboutHero />
      <MapComponent />
    </div>
  );
};

export default AboutComponent;
