import React from "react";

const Heading = ({ title, className, ...rest }) => {
  return (
    <div className={` text-[1.6rem] font-bold ${className}`} {...rest}>
      {title}
    </div>
  );
};

export default Heading;
