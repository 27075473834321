import React from "react";
import { TransparentButton } from "../../ReusableComponents";
import Heading from "../../ReusableComponents/Heading";
import {
  platformsIntroData,
  platformsIntroDescription,
} from "../../../Constants/HomePageConstants";
import { Link } from "react-router-dom";

const PlatformsIntro = () => {
  return (
    <div className="w-full flex-center flex-col mt-[2.4rem]">
      {/* <TransparentButton title={"WHAT WE OFFER"} /> */}
      <Heading
        title={"Platforms"}
        className={"text-secondary uppercase font-semibold text-[1.6rem] "}
      />
      <span className=" text-[1rem] sm:text-[0.7rem] text-center max-w-[90%] lg:max-w-[45%] mt-[0.4rem] text-secondary ">
        {platformsIntroDescription}
      </span>
      <div className="flex items-start justify-center flex-wrap gap-[1.4rem] mt-[1.5rem] w-full 2xl:max-w-[90%]">
        {platformsIntroData?.map((item, index) => (
          <Link
            className="w-[13rem] flex-center flex-col gap-[0.8rem] pt-[1rem] hover:bg-gray-100 p-1 rounded-lg duration-500 cursor-pointer hover:scale-105 "
            key={index}
            to={`/services/service-details?service=${item?.id}`}
          >
            <span className="size-[5rem] flex-center">
              <img src={item?.icon} className="size-full" alt="" />
            </span>
            <span className="text-[1rem] sm:text-[0.8rem] text-secondary text-center font-semibold">
              {item?.title}
            </span>
            <span className=" text-[0.9rem] sm:text-[0.65rem] text-center text-secondary opacity-50">
              {" "}
              {item?.description}{" "}
            </span>
            <span className="cursor-pointer text-[0.7rem] font-semibold mt-[0.4rem] text-primary ">
              <Link to={`/services/service-details?service=${item?.id}`}>
                {`Learn More`}&nbsp;&nbsp;&nbsp;{`->`}
              </Link>
            </span>
            <hr className="w-full mt-[1rem]" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PlatformsIntro;
