import React, { useState } from "react";
import { kiamLogo } from "../../Assets";
import {
  addressArray,
  enquiryMailId,
  footerLinks,
  phone1,
  phone2,
} from "../../Constants";
import { Link } from "react-router-dom";
import { Button } from "../ReusableComponents";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../ReusableComponents/Loader";

//Swiper Imports
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const Footer = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    organization: "",
    services: [],
    contact_no: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!validateEmail(formData?.email)) {
      newErrors.email = "Enter Valid Email";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await axios
        .post("/iesg-website/book-demo", {
          ...formData,
          enquiry_subject: `Client Inquiry`,
          enquiry_body: `Dear Team,\n\nA new client has connected with us via email. Here are the details:\n\nEmail : ${formData.email}\n\nPlease follow up with this client at your earliest convenience.\n\nBest regards,\nSales Manager\nIESG Pvt. Ltd`,
          feedback_subject: `Thank you for the inquiry!`,
          feedback_body: `Hi, Thank you for reaching out to us. We appreciate your interest in our products and services. Our team will reach out to you shortly to discuss your requirements in detail.\n\nIf you have any immediate questions or need further information, please feel free to contact us .\n\nWe look forward to successful collaboration!`,
        })
        .then((response) => {
          toast.success("Email Sent successfully");
          setFormData({
            user_name: "",
            email: "",
            organization: "",
            contact_no: "",
            services: [],
            comment: "",
          });
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      setLoading(false);
    }
  };
  return (
    <div className="h-auto w-full flex-center flex-col mt-[2rem] gap-[2rem] ">
      <div className="flex-center w-full">
        <Button
          className={"rounded-2xl px-2 py-1 text-[0.7rem] "}
          onClick={() =>
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          Back to top
        </Button>
      </div>
      <div className="flex-start sm:flex sm:justify-start w-full pl-[4rem] sm:pl-[2rem] xl:pl-[3rem] ">
        <img src={kiamLogo} alt="KIAM" className="object-contain w-[7rem]" />
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-start justify-start lg:justify-start gap-[2rem] xl:gap-[2rem] px-[1rem] flex-wrap">
        {footerLinks?.map((link, index) => {
          return (
            <div
              key={index}
              className={` max-w-[12rem] md:max-w-[12rem] lg:max-w-[10rem] ${
                link?.id === "company"
                  ? "flex-start flex-col"
                  : "hidden sm:flex sm:flex-start sm:flex-col "
              } `}
            >
              <span className="uppercase text-[1.1rem] sm:text-[1rem] text-secondary font-semibold w-full text-center sm:text-start ">
                {link.title}
              </span>
              <div className="flex-start flex-col mt-[1rem] gap-[0.5rem] ">
                {link.sublinks?.map((item, index) => (
                  <Link
                    key={index}
                    to={
                      link?.id === "company"
                        ? item?.sublinkid
                        : link?.id === "products"
                        ? `/products/product-details?product=${item?.sublinkid}`
                        : link?.id === "services"
                        ? `/services/service-details?service=${item?.sublinkid}`
                        : "/"
                    }
                  >
                    <span className="text-[0.8rem] lg:text-[0.7rem] opacity-70">
                      {item?.sublinktitle}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          );
        })}
        <div className="flex-start flex-col max-w-[12rem] md:max-w-[12rem] lg:max-w-[11rem]">
          <span className="uppercase text-[1.1rem] sm:text-[1rem] text-secondary font-semibold w-full text-center sm:text-start  ">
            ADDRESS
          </span>
          <div className="mt-[1rem] gap-[0.6rem] flex-start flex-col sm:hidden">
            <Swiper
              grabCursor={true}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={1000}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                400: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                639: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
              }}
              // spaceBetween={30}
              modules={[Autoplay, Pagination]}
              className=" max-w-[12rem] md:max-w-[12rem] lg:max-w-[11rem] hidden"
            >
              {addressArray?.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="flex flex-col items-start justify-start gap-[1rem]"
                >
                  <div className="text-secondary text-[0.9rem]">
                    {item?.title}
                  </div>
                  <a
                    href={item?.link}
                    target="_blank"
                    className="text-[0.8rem] lg:text-[0.7rem]  opacity-70"
                    rel="noreferrer"
                  >
                    {item?.address}
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="mt-[1rem] gap-[0.6rem] hidden sm:flex sm:justify-start sm:items-start sm:flex-col">
            {addressArray?.map((item, index) => (
              <div key={index} className="flex-start flex-col gap-[0.5rem] ">
                <span className="text-secondary text-[0.9rem]">
                  {item?.title}
                </span>
                <a
                  href={item?.link}
                  target="_blank"
                  className="text-[0.8rem] lg:text-[0.7rem]  opacity-70"
                  rel="noreferrer"
                >
                  {item?.address}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-start flex-col max-w-[12rem] md:max-w-[13rem] lg:max-w-[12rem] gap-[2rem]">
          <div className="flex-start w-full">
            <div className="flex-start flex-col gap-[0.5rem]">
              <span className="uppercase text-[1.1rem] sm:text-[1rem] text-secondary font-semibold w-full text-center sm:text-start ">
                CONTACT US
              </span>
              <div className="flex-start flex-col gap-[0.5rem] mt-[1rem]">
                <a
                  href={`mailto:${enquiryMailId}`}
                  className="text-[0.8rem] lg:text-[0.7rem]  opacity-70"
                >
                  Mail - {enquiryMailId}
                </a>
                <div className="flex-start gap-[0.5rem] flex-wrap">
                  <a
                    href={`tel:${phone1}`}
                    className="text-[0.8rem] lg:text-[0.7rem]  opacity-70"
                  >
                    Call - {phone1}
                  </a>
                  <span className="text-[0.8rem] lg:text-[0.7rem]  opacity-70">
                    /
                  </span>
                  <a
                    href={`tel:${phone2}`}
                    className="text-[0.8rem] lg:text-[0.7rem]  opacity-70"
                  >
                    {phone2}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-start flex-col w-full">
            <div className="flex-start flex-col gap-[0.5rem]">
              <span className="uppercase text-[1.1rem] sm:text-[1rem] text-secondary font-semibold w-full text-center sm:text-start ">
                Join Us
              </span>
              <span className="text-[0.8rem] sm:text-[0.7rem]  opacity-70">
                Join millions of people who build a fully integrated sales and
                maketing solution.
              </span>
            </div>
            <div className="flex-center mt-[2rem]">
              <input
                type="email"
                name="email"
                placeholder="Enter mail ID"
                className="w-[80%] rounded-l-2xl bg-[#D9D9D9] text-tertiary text-[0.9rem] py-1 px-4 "
                value={formData.email}
                onChange={handleInputChange}
              />
              <Button
                onClick={handleSubmit}
                className={
                  " flex-center rounded-r-2xl py-1 px-3 text-[0.9rem] focus:border border-black mr-4 "
                }
              >
                Submit
                {loading ? (
                  <div className="ml-2">
                    <Loader />
                  </div>
                ) : null}
              </Button>
            </div>
            {errors.email && (
              <span className="text-[0.7rem] my-[0.6rem] text-red-500 px-[0.5rem]">
                {errors.email}
              </span>
            )}
          </div>
        </div>
      </div>
      <hr className="w-full text-black " />
      <div className=" flex-center lg:flex-between mb-[1.6rem] w-full flex-wrap gap-[1rem] ">
        <span className="text-[0.8rem] text-center text-tertiary">{` Copyright @${new Date().getFullYear()} KIAM INTL PVT LTD. All Rights Reserved.`}</span>
        <span className="text-[0.8rem] text-center text-tertiary">
          Terms & Conditions ~ Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default Footer;
