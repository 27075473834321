import React from "react";
import { intrusionData } from "../../../../Constants";
import { EnquireNowButton, FeaturePoints } from "../../../ReusableComponents";

const Intrusion = () => {
  const {
    id,
    applicationTitle,
    applications,
    image1,
    image2,
    outputs,
    outputsTitle,
    sensors,
    sensorsTitle,
  } = intrusionData;
  return (
    <div id={id} className="flex-center flex-col w-full gap-[2rem] mt-[1rem] ">
      <div className="flex-center w-[100%] md:w-[80%] xl:w-[75%] 2xl:w-[55%] flex-col gap-[2rem] ">
        <img
          src={image1}
          alt="IOT based Intrusion"
          className="size-full object-contain"
        />
        <EnquireNowButton
          className={"text-[0.68rem] mt-[2rem] px-[4rem] py-[0.3rem] "}
        />
      </div>
      <FeaturePoints title={applicationTitle} points={applications} />
      <div className="flex-center w-[100%] md:w-[80%] xl:w-[75%] 2xl:w-[55%] flex-col ">
        <img
          src={image2}
          alt="Intrusion"
          className="size-full object-contain"
        />
      </div>
      <div className="flex justify-start items-start flex-wrap gap-[2rem] ">
        <div className="flex-start lg:flex-start w-[100%] lg:w-[50%]">
          <FeaturePoints title={sensorsTitle} points={sensors} disableLine />
        </div>
        <div className=" flex-start  lg:flex-start w-[100%] lg:w-[40%]">
          <FeaturePoints title={outputsTitle} points={outputs} disableLine />
        </div>
      </div>
    </div>
  );
};

export default Intrusion;
