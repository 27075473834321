import React from "react";
import HomeComponent from "../Components/SubComponents/Home/HomeComponent";

const HomePage = () => {
  return (
    <>
      <HomeComponent />
    </>
  );
};

export default HomePage;
